import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  BaixoPage,
  BotaoEnviarCatalogo,
  BotaoLoginTopo,
  BotaoPopCancelar,
  BotaoPopSolicitar,
  BotaoSolicitar,
  CampoFormLogin,
  ForaBotaoEnviaCatalogo,
  ForaBotaoSolicitar,
  ForaCatalogo,
  ForaFiltro,
  ForaImgCatalogo,
  ForaImgRedes,
  ForaImgRedesTopo,
  ForaItemCatalogo,
  ForaItemRedes,
  ForaItensCatalogo,
  ForaLogoPopLogin,
  ForaMenuTopo,
  ForaPopCatalogo,
  ForaRedesTopo,
  ForaRodapeBaixo,
  ForaSolicitar,
  ForaTextItem,
  ForaTextRedes,
  ForaTopo,
  FormLogin,
  FundoCatalogo,
  FundoPopLogin,
  FundoRodape,
  ImagemCatalogo,
  ImagemRedes,
  ImgLogoRodape,
  ImgRedes,
  ImgRedesTopo,
  ItemCatalogo,
  LogoPopLogin,
  LogoRodape,
  LogoTopo,
  MenuRodape,
  Option,
  RedesRodape,
  Select,
  TextCatalogo,
  TextCatalogo1,
  TextEsqueceuSenha,
  TextFiltro,
  TextMenuRodape,
  TextMenuTopo,
  TextPopLogin,
  TextPopSolicitar,
  TextRedes1,
  TextRedes2,
  TextXPop,
} from "./EstilosCatalogo";
import ImgCatalogo from "./Images/ImgInfluencer.png";
import ImgInsta from "./Images/ImgInsta.png";
import ImgTik from "./Images/ImgTik.png";
import ImgLogoPopLogin from "./Images/LogoPopLogin.png";
import ImgLogoUrl from "./Images/LogoRodape.png";
import ImgTopo from "./Images/LogoTopo.png";
import ImgBaixo1 from "./Images/RedesBaixo1.png";
import ImgBaixo2 from "./Images/RedesBaixo2.png";
import ImgBaixo3 from "./Images/RedesBaixo3.png";
import ImgBaixo4 from "./Images/RedesBaixo4.png";
import ImgRedes1 from "./Images/RedesTopo1.png";
import ImgRedes2 from "./Images/RedesTopo2.png";
import ImgRedes3 from "./Images/RedesTopo3.png";
import ImgRedes4 from "./Images/RedesTopo4.png";

import Header from "./Componentes/Header";

function Catalogo() {
  const myFilter = useRef();
  const minhaPage = useRef(1);
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [filtro, setFiltro] = useState("");
  const [dataCatalogo, setDataCatalogo] = useState([]);
  const [dataCategoria, setDataCategoria] = useState([]);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(true);

  const carregaMais = () => {
    // setPage((page) => page + 1);
    fetch(
      myFilter.current === "" || myFilter.current === 0
        ? `https://wbmd.com.br/app/Catalogo.php?page=${minhaPage.current}`
        : `https://wbmd.com.br/app/Catalogo.php?page=${minhaPage.current}&id_tb_categoria=${myFilter.current}`,
      {}
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(myFilter.current);
        console.log(
          myFilter.current === "" || myFilter.current === 0
            ? `https://wbmd.com.br/app/Catalogo.php?page=${minhaPage.current}`
            : `https://wbmd.com.br/app/Catalogo.php?page=${minhaPage.current}&id_tb_categoria=${myFilter.current}`
        );
        console.log(responseJson);
        setDataCatalogo((dataCatalogo) => [...dataCatalogo, ...responseJson]);

        console.log("length", responseJson.length);
        console.log("total_rows", responseJson[0].total_rows);

        if (responseJson.length < responseJson[0].total_rows) {
          setNext(true);
        } else {
          setNext(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetch(`https://wbmd.com.br/app/Catalogo.php?page=${minhaPage.current}`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setDataCatalogo(responseJson);
        //console.log(responseJson);
        //setPage(2);
        console.log("length", responseJson.length);
        console.log("total_rows", responseJson[0].total_rows);

        if (responseJson.length < responseJson[0].total_rows) {
          setNext(true);
        } else {
          setNext(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`https://wbmd.com.br/app/Categoria.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setDataCategoria(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function AbrePopCatalogo(x) {
    document.getElementById("FundoPopSolicitar").style.display = "flex";
    document.getElementById("SpanSolicitar").innerHTML = x;
  }

  function FechaPopCatalogo() {
    document.getElementById("FundoPopSolicitar").style.display = "none";
  }

  return (
    <>
      <Header tipo={"home"} />
      <FundoPopLogin id="FundoPopSolicitar">
        <ForaSolicitar>
          <TextPopSolicitar>
            SOLICITAR INFORMAÇÕES DE{" "}
            <span id="SpanSolicitar">SEBASTIAN YATRA?</span>
          </TextPopSolicitar>
          <BotaoPopSolicitar target="_blank" href="https://wa.me/5511934018170">
            SOLICITAR
          </BotaoPopSolicitar>
          <BotaoPopCancelar onClick={() => FechaPopCatalogo()}>
            CANCELAR
          </BotaoPopCancelar>
        </ForaSolicitar>
      </FundoPopLogin>

      <FundoCatalogo>
        <ForaCatalogo>
          <TextCatalogo>Catálogo de Influenciadores</TextCatalogo>
          <ForaFiltro>
            <TextFiltro>FILTRO</TextFiltro>
            <Select
              onChange={(e) => {
                setDataCatalogo([]);
                setFiltro(e.target.value);
                // setPage(1);
                myFilter.current = e.target.value;
                console.log(e.target.value);
                //
                // setFiltro(e.target.value);
                minhaPage.current = 1;
                carregaMais();
              }}
            >
              <Option value="0">Todas as Categorias</Option>
              {dataCategoria.map((Categoria) => (
                <Option value={Categoria.id_tb_categoria}>
                  {Categoria.c_nome}
                </Option>
              ))}
            </Select>
          </ForaFiltro> 
          <ForaItensCatalogo>
            {dataCatalogo.map((Catalogo) => (
              <ItemCatalogo>
                <ForaItemCatalogo>
                  <ForaImgCatalogo>
                    <ImagemCatalogo src={Catalogo.c_imagem} />
                  </ForaImgCatalogo>
                  <ForaTextItem>
                    <TextCatalogo1>{Catalogo.c_nome}</TextCatalogo1>
                    <ForaItemRedes>
                      <ForaImgRedes>
                        <ImagemRedes src={ImgTik} />
                      </ForaImgRedes>
                      <ForaTextRedes>
                        <TextRedes1>TikTok</TextRedes1>
                        <TextRedes2>
                          <span>{Catalogo.c_seguidores_tt}</span> Seguidores
                        </TextRedes2>
                        <TextRedes2>
                          <span>{Catalogo.c_curtidas_tt}</span> Seguidores
                        </TextRedes2>
                      </ForaTextRedes>
                    </ForaItemRedes>
                    <ForaItemRedes>
                      <ForaImgRedes>
                        <ImagemRedes src={ImgInsta} />
                      </ForaImgRedes>
                      <ForaTextRedes>
                        <TextRedes1>Instagram</TextRedes1>
                        <TextRedes2>
                          <span>{Catalogo.c_seguidores_ig}</span> Seguidores
                        </TextRedes2>
                        <TextRedes2>
                          <span>{Catalogo.c_views_medios_ig}</span> Seguidores
                        </TextRedes2>
                      </ForaTextRedes>
                    </ForaItemRedes>
                    <ForaBotaoSolicitar>
                      <BotaoSolicitar
                        onClick={() => AbrePopCatalogo(Catalogo.c_nome)}
                      >
                        Solicitar Informações
                      </BotaoSolicitar>
                    </ForaBotaoSolicitar>
                  </ForaTextItem>
                </ForaItemCatalogo>
              </ItemCatalogo>
            ))}
          </ForaItensCatalogo>

          <BaixoPage
            onClick={() => {
              setPage((page) => page + 1);
              minhaPage.current = minhaPage.current + 1;
              carregaMais();
            }}
          >
            Carregar mais
          </BaixoPage>
        </ForaCatalogo>
      </FundoCatalogo>

      <FundoRodape>
        <ForaRodapeBaixo>
          <LogoRodape>
            <ImgLogoRodape src={ImgLogoUrl} />
          </LogoRodape>
          <MenuRodape>
            <TextMenuRodape>Home</TextMenuRodape>
            <TextMenuRodape>Serviços</TextMenuRodape>
            <TextMenuRodape>Influenciadores</TextMenuRodape>
            <TextMenuRodape>login</TextMenuRodape>
            <TextMenuRodape>Contato</TextMenuRodape>
          </MenuRodape>
          <RedesRodape>
            <ImgRedesTopo src={ImgBaixo1} />
            <ImgRedesTopo src={ImgBaixo2} />
            <ImgRedesTopo src={ImgBaixo3} />
            <ImgRedesTopo src={ImgBaixo4} />
          </RedesRodape>
        </ForaRodapeBaixo>
      </FundoRodape>
    </>
  );
}

export default Catalogo;
