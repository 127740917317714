import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import CurrencyInput from "react-currency-input";
import { socket, URLsocket } from "./Componentes/Config";
import { AlertForm } from "./Componentes/Funcoes";
import Header from "./Componentes/Header";
import Footer from "./Componentes/Rodape";
import MyDialog  from "./Componentes/MyDialog";


import {
  ForaBotaoEnviaCatalogo,
  BotaoEnviarCatalogo,
  TextXPop,
  FundoPopLogin,
  ForaLogoPopLogin,
  LogoPopLogin,
  CampoFormLogin,
  FundoInfluencer,
  ForaInfluencer,
  TextInfluencer,
  ForaConteudoInfluencer,
  EsquerdaInfluencer,
  ForaImgInfluencer,
  ImagemInfluencer,
  DireitaInfluencer,
  ItemConteudoDir,
  TextConteudoDir1,
  ForaLinhaDir,
  ForaEditar,
  ImagemEditar,
  TextEditar,
  RiscoItem,
  ForaImagemEditar,
  ForaConteudoDir1,
  ForaConteudoDir2,
  TextEstat,
  ForaItemRedes,
  ForaImg,
  ImagemRedes,
  ForaTextRedes,
  TextRedes1,
  TextRedes2,
  TextRedes3,
  ItemConteudoDir2,
  ForaCategorias,
  TextCategorias,
  ForaItemCategoria,
  ItemCategoria,
  QuadradoCategoria,
  TextItemCategoria,
  RiscoCategoria,
  TextTrocarSenha,
  BotaoTrocarSenha,
  ForaSenha,
  TextStatus,
  FormTrocarSenha,
  ForaPopTrocarSenha,
} from "./EstilosInfluenciador";

import './indexInflu.css'




import ImgLogoPopLogin from "./Images/LogoPopLogin.png";
import ImgInfluencer from "./Images/ImgInfluencer.png";
import ImgEditar from "./Images/ImgEditar.png";
import ImgTik from "./Images/ImgTik.png";
import ImgInsta from "./Images/ImgInsta.png";
import { TabletRounded } from "@material-ui/icons";
import { Alert } from "react-bootstrap";


function Login() {
  
  useForm({ defaultValues: { c_nome: "123" } });

  const refTipo = useRef(0);
  
  const { register, handleSubmit, errors, control, setValue } = useForm();

  const history = useHistory();

  const [valorCorreto, setValorCorreto] = useState(0);

  const [valores, setValores] = useState([]);
  const [valor, setValor] = useState(0);

  const [valorFeed, setValorFeed] = useState(0);
  const [feed, setFeed] = useState(0);

  const [valorStories, setValorStories] = useState(0);
  const [stories, setStories] = useState(0);

  const [reels, setReels] = useState(0);
  const [load, setLoad] = useState(true);
  const [categoria, setCategoria] = useState([]);
  const [nome, setNome] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [teste, setTeste] = useState("xxxx");
  const [open, setOpen] = useState(false);
  const [tipo, setTipo] = useState(0);


  const [status, setStatus] = useState(false);


  function AbreTrocaSenha() {
    document.getElementById("FormTrocarSenha").style.display = "flex";
  }

  function FechaTrocarSenha() {
    document.getElementById("FormTrocarSenha").style.display = "none";
  }

  const Inicial = async (data, e) => {
    const response = await fetch(URLsocket + "app/PegaCategoria.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tb_user: localStorage.getItem("id_tb_user"),
      }),
    });

    const jsonResult = await response.json();

    if (jsonResult[0].status === true) {
      setCategoria(jsonResult);
    }

    const response1 = await fetch(URLsocket + "app/PegaStatus.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tb_user: localStorage.getItem("id_tb_user"),
      }),
    });

    const jsonResult1 = await response1.json();

    if (jsonResult1[0].id_tb_status == 0) {
      setStatus("Aguardando");
    } else {
      setStatus("Ativo");
    }
    setLoad(false);
  };

  const CategoriaUpdate = async (data, e) => {
    const response = await fetch(URLsocket + "app/CategoriaUpdate.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: data,
    });

    const obj = JSON.parse(data);

    const jsonResult = await response.json();
    console.log(jsonResult);
    if (jsonResult[0].status === true) {
      let newArray = [...categoria];
      //delete newArray[index];
      if (obj.id_tb_status) {
        newArray[obj.index].id_tb_status = false;
      } else {
        newArray[obj.index].id_tb_status = true;
      }
      setCategoria(newArray);
      console.log("index", obj.index);
    }
  };


  useEffect(() => {

    
       

    fetch(`https://wbmd.com.br/app/valores.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setValores(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

      fetch(`https://wbmd.com.br/app/valores_feed.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setValorFeed(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

      fetch(`https://wbmd.com.br/app/valores_stories.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setValorStories(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    setNome(localStorage.getItem("c_nome"));
    setValor(parseFloat(localStorage.getItem("c_valor_tt")));
    setReels(parseFloat(localStorage.getItem("c_valor_ig_reels")));
    setFeed(parseFloat(localStorage.getItem("c_valor_ig_feed")));
    setStories(parseFloat(localStorage.getItem("c_valor_ig_stories")));
    setCidade(localStorage.getItem("c_cidade"));

    //document.getElementById(localStorage.getItem("id_tb_estado"))

    setEstado(localStorage.getItem("id_tb_estado"));
    // // setValue("c_nomeInflu", localStorage.getItem("c_nome"));
    // setValue(
    //   localStorage.getItem("c_valor").replaceAll(",", ".").replaceAll(".", ",")
    // );

    if (
      localStorage.getItem("status") === "false" ||
      localStorage.getItem("status") === null
    ) {
      history.push("/");
    }

    Inicial();

    PegaStatus();
  }, []);


  const PegaStatus = async (data, e) => {
    const response = await fetch(URLsocket + "app/PegaStatus.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tb_user: localStorage.getItem("id_tb_user"),
      }),
    });

    const jsonResult = await response.json();

    if (jsonResult[0].id_tb_status == 0) {
      setStatus("Aguardando");
    } else {
      setStatus("Ativo");
    }
  };

  const onSenha = async (data, e) => {
    if (data.c_senha === "" || data.c_senha === undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (data.c_senha !== "" && data.c_senha !== undefined) {
      const response = await fetch(URLsocket + "app/TrocaSenha.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_tb_user: localStorage.getItem("id_tb_user"),
          c_senha: data.c_senha,
        }),
      });

      const jsonResult = await response.json();

      if (jsonResult[0].status === true) {
        document.getElementById("FormTrocarSenha").style.display = "none";
        AlertForm("Senha Alterada com sucesso!");
        e.preventDefault();

        localStorage.setItem("c_senha", jsonResult[0].c_senha);
      } else {
        AlertForm("Erro Tente Novamente!");
        e.preventDefault();
      }
    }
  };

  const onSubmitInflu = async (data, e) => {
    if (nome === "" || nome === undefined) {
      AlertForm("O campo Nome Influenciador é de preenchimento obrigatório!");
    }

    if (nome !== "" && nome !== undefined) {
      const response = await fetch(URLsocket + "app/EditaInflu.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_tb_user: localStorage.getItem("id_tb_user"),
          c_nomeInflu: nome,
        }),
      });

      const jsonResult = await response.json();

      if (jsonResult[0].status === true) {
        AlertForm("Nome Alterado com sucesso!");

        localStorage.setItem("c_nome", jsonResult[0].c_nomeInflu);
        // setNome("c_nomeInflu", localStorage.getItem("c_nome"));
      } else {
        AlertForm("Erro Tente Novamente!");
      }
    }
  };

  const onSubmitCidade = async (data, e) => {
    if (cidade === "" || cidade === undefined) {
      AlertForm("O campo Cidade Influenciador é de preenchimento obrigatório!");
    }

    if (cidade !== "" && cidade !== undefined) {
      const response = await fetch(URLsocket + "app/EditaInfluCidade.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id_tb_user: localStorage.getItem("id_tb_user"),
          c_cidade: cidade,
        }),
      });

      const jsonResult = await response.json();

      if (jsonResult[0].status === true) {
        AlertForm("Cidade Alterado com sucesso!");

        localStorage.setItem("c_cidade", jsonResult[0].c_cidade);
        //setCidade("c_cidade", localStorage.getItem("c_cidade"));
      } else {
        AlertForm("Erro Tente Novamente!");
      }
    }
  };
  
  const MudaEstado = async() => {
    var select = document.getElementById('select');
    var value = select.options[select.selectedIndex].value;

    //document.getElementById(value) = selected;

    const response = await fetch(URLsocket + "app/EditaInfluEstado.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id_tb_user: localStorage.getItem("id_tb_user"),
        estado: value,
      }),
    });

    const jsonResult = await response.json();

    if (jsonResult[0].status === true) {
      AlertForm("Estado Alterado com sucesso!");

      localStorage.setItem("id_tb_estado", jsonResult[0].estado);
      //setCidade("c_cidade", localStorage.getItem("c_cidade"));
    } else {
      AlertForm("Erro Tente Novamente!");
    }
  }


  function retorno(tipo) {
    //grava no banco dados
    let data;
    let file;
    //TikTok
    if(tipo === 1){
      const myValue = valorCorreto.toString().replaceAll(".", "").replaceAll(",", ".");
      file = "app/EditaInflu2.php"
      data = {
        c_valor_tt: myValue,
        id_tb_user: localStorage.getItem("id_tb_user"),
      }
      setValor(myValue);
      localStorage.setItem("c_valor_tt",myValue)
      localStorage.setItem("c_data_tt", false);
    }
    //instagram Feed
    else if(tipo === 2){
      const myValue = valorCorreto.toString();
      file = "app/EditaInfluFeed.php"
      data = {
        c_valor_ig_feed: myValue,
        id_tb_user: localStorage.getItem("id_tb_user"),
      }
      setFeed(valorCorreto.toString().replaceAll(".",","));
      localStorage.setItem("c_valor_ig_feed",myValue)
      localStorage.setItem("c_data_ig_feed", false);


    }
    //instagram Stories
    else if(tipo === 3){
      const myValue = valorCorreto.toString();
      file = "app/EditaInfluStories.php"
      data = {
        c_valor_ig_stories: myValue,
        id_tb_user: localStorage.getItem("id_tb_user"),
      }
      setStories(valorCorreto.toString().replaceAll(".",","));
      localStorage.setItem("c_valor_ig_stories",myValue)
      localStorage.setItem("c_data_ig_stories", false);

    }

    else if(tipo === 4){
      const myValue = valorCorreto.toString().replaceAll(".", "").replaceAll(",", ".");
      file = "app/EditaInfluReels.php"
      data = {
        c_valor_ig_reels: myValue,
        id_tb_user: localStorage.getItem("id_tb_user"),
      }
      localStorage.setItem("c_valor_ig_reels",myValue)
      localStorage.setItem("c_data_ig_reels", false);
      setReels(myValue);

    }

    
      fetch(URLsocket + file, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          data
        ),
      });

  }

 

  function Fechar(tipo) {
    console.log(valorCorreto);
    //grava no banco dados
    if(tipo === 1){
      setValor(localStorage.getItem("c_valor_tt").replaceAll(",",".").replaceAll(".",","));
    }
    //instagram Feed
    else if(tipo === 2){
      //alert(localStorage.getItem("c_valor_ig_feed"));
      setFeed(localStorage.getItem("c_valor_ig_feed").replaceAll(",",".").replaceAll(".",","));
    }
    //instagram Stories
    else if(tipo === 3){
      setStories(localStorage.getItem("c_valor_ig_stories").replaceAll(",",".").replaceAll(".",","));
    }

    else if(tipo === 4){
      setReels(localStorage.getItem("c_valor_ig_reels").replaceAll(",",".").replaceAll(".",","));
    }

   

  }

 
  const onSubmitValorFeed = async (data, e) => {
    setTipo(2);
    refTipo.current = 2;
    if (feed === "" || feed === undefined) {
    } else {
      if (
        verifyPriceFeed(localStorage.getItem("c_seguidores_ig_extenso"), "feed")
      ) {
        await fetch(URLsocket + "app/EditaInfluFeed.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_valor_ig_feed: feed.replaceAll(".", "").replaceAll(",", "."),
            id_tb_user: localStorage.getItem("id_tb_user"),
          }),
        });
        setStatus("Ativo");
        
        localStorage.setItem("c_valor_ig_feed", feed);
        localStorage.setItem("c_data_ig_feed", false);
        AlertForm("Valor Editado com sucesso!");
      } else {
        //alert(valorCorreto)
        // alert(localStorage.getItem("c_valor_ig_feed"))
        setOpen(true);
        //setFeed(localStorage.getItem("c_valor_ig_feed"));
        //AlertForm("Valor Incorreto!");
      }
    }
  };

  const verifyPriceFeed = (quantidade) => {
    let p_valor = 0;
    // eslint-disable-next-line array-callback-return
    valorFeed.map((item, key) => {
      console.log(quantidade , ' <= ', parseInt(item.c_maximo))
      if (
        //price >= parseInt(item.c_minimo) &&
        quantidade >= parseInt(item.c_maximo)
      ) {
        p_valor = item.c_valor;
      }
    });


    if (p_valor === 0) {
      let z_valor =
        parseInt(localStorage.getItem("c_seguidores_ig_extenso")) - 10000000;
      p_valor = parseInt(z_valor / 1000000) * 500 + 4000;
    }

    if (feed.replaceAll(".", "").replaceAll(",", ".") <= parseInt(p_valor)) {
      //console.log("p_valor", p_valor, "feed", feed.replaceAll(".", "").replaceAll(",", "."));
      //setValorCorreto(p_valor);
      return true;
    } else {
      //alert(p_valor)
      setValorCorreto(p_valor);
      //setTipo(2);
      return false;
    }
  };



  const onSubmitValorStories = async (data, e) => {
    setTipo(3);
    refTipo.current = 3;
    if (stories === "" || stories === undefined) {
      //AlertForm("O campo Valor Medio é de preenchimento obrigatório!");
      //e.preventDefault();
    } else {
      if (
        verifyPriceStories(localStorage.getItem("c_views_medios_ig"), "feed")
      ) {
        await fetch(URLsocket + "app/EditaInfluStories.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_valor_ig_stories: stories.replaceAll(".", "").replaceAll(",", "."),
            id_tb_user: localStorage.getItem("id_tb_user"),
          }),
        });
        setStatus("Ativo");
        localStorage.setItem("c_valor_ig_stories", stories);

        localStorage.setItem("c_data_ig_stories", false);
        AlertForm("Valor Editado com sucesso!");
      } else {
        setOpen(true);
        //setStories(localStorage.getItem("c_valor_ig_stories"));
        //AlertForm("Valor Incorreto!");
      }
    }
  };
  
  const verifyPriceStories = (quantidade) => {
    let p_valor = 0;
    valorStories.map((item, key) => {
      if (
        //price >= parseInt(item.c_minimo) &&
        quantidade <= parseInt(item.c_maximo)
      ) {
        p_valor = item.c_valor;
      }
    });

    if (p_valor === 0) {
      let z_valor =
        parseInt(localStorage.getItem("c_views_medios_ig")) - 10000000;
      p_valor = parseInt(z_valor / 1000000) * 500 + 4000;
    }

    if (stories.replaceAll(".", "").replaceAll(",", ".") <= parseInt(p_valor)) {
      return true;
    } else {
      setValorCorreto(p_valor);
      return false;
    }
  };


  const onSubmitValorTikTok = (data, e) => {
    setTipo(1); 
    refTipo.current = 1;
    if (valor === "" || valor === undefined) {
      AlertForm("O campo Valor Medio é de preenchimento obrigatório!");
      e.preventDefault();
    } else {
      let p_valor = 0;
    
      if (p_valor === 0) {
        let z_valor = localStorage.getItem("c_views_medios_tt") * 0.005;
        p_valor = parseInt(z_valor);
      }
      console.log('z_valor',p_valor)
      if (valor.replaceAll(".", "").replaceAll(",", ".") <= parseInt(p_valor)) {
        fetch(URLsocket + "app/EditaInflu2.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_valor_tt: valor.replaceAll(".", "").replaceAll(",", "."),
            id_tb_user: localStorage.getItem("id_tb_user"),
          }),
        });
        setStatus("Ativo");
        localStorage.setItem("c_valor_tt", valor.replaceAll(".", "").replaceAll(",", "."));

        localStorage.setItem("c_data_tt", false);
        AlertForm("Valor Editado com sucesso!");
      } else {
        setValorCorreto(p_valor);
        setOpen(true);
        //setValor(localStorage.getItem("c_valor_tt"));
      }

    }
  };


  const onSubmitValorReels = (data, e) => {
    setTipo(4); 
    refTipo.current = 4;
    if (reels === "" || reels === undefined) {
      AlertForm("O campo Valor Medio é de preenchimento obrigatório!");
      e.preventDefault();
    } else {
      let p_valor = 0;
  
      if (p_valor === 0) {
        let z_valor = localStorage.getItem("c_views_medios_ig") * 0.006;
        p_valor = parseInt(z_valor);
      }

      if (reels.replaceAll(".", "").replaceAll(",", ".") <= parseInt(p_valor)) {
        fetch(URLsocket + "app/EditaInfluReels.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_valor_reels: reels.replaceAll(".", "").replaceAll(",", "."),
            id_tb_user: localStorage.getItem("id_tb_user"),
          }),
        });
        setStatus("Ativo");
        localStorage.setItem("c_valor_ig_reels", reels);

        localStorage.setItem("c_data_ig_reels", false);
        AlertForm("Valor Editado com sucesso!");
      } else {
        setValorCorreto(p_valor);
        setOpen(true);
      }

    }
  };


  function visivel(res) {
    setOpen(res)
  }


  return (
    <>
      <Header tipo={"home"} />

      

      <FundoPopLogin id="FormTrocarSenha">

        <ForaPopTrocarSenha>
          <TextXPop onClick={() => FechaTrocarSenha()}>X</TextXPop>
          <ForaLogoPopLogin>
            <LogoPopLogin src={ImgLogoPopLogin} />
          </ForaLogoPopLogin>
          <FormTrocarSenha onSubmit={handleSubmit(onSenha)}>
            <CampoFormLogin>
              <Controller
                control={control}
                ref={register}
                id="c_senha"
                name="c_senha"
                type="password"
                placeholder={"Senha"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoFormLogin>
            <ForaBotaoEnviaCatalogo>
              <BotaoEnviarCatalogo type="submit">
                Trocar Senha
              </BotaoEnviarCatalogo>
            </ForaBotaoEnviaCatalogo>
          </FormTrocarSenha>
        </ForaPopTrocarSenha>
      </FundoPopLogin>

      <FundoInfluencer>
        <ForaInfluencer>
          <TextInfluencer>Dados do seu perfil</TextInfluencer>

          <ForaConteudoInfluencer>
            <EsquerdaInfluencer>
              <ForaImgInfluencer>
                <ImagemInfluencer src={"./upload/user/" + localStorage.getItem("id_tb_user") + "a." + localStorage.getItem("c_extensao1")} />
              </ForaImgInfluencer>
            </EsquerdaInfluencer>
            <DireitaInfluencer>
       
              <ForaConteudoDir1>
                <ItemConteudoDir>
               
                  <TextConteudoDir1>Status usuário</TextConteudoDir1>
                  <ForaLinhaDir>
                    <TextStatus>{status}</TextStatus>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir>
                {/* <button onClick={()=>{
              
                  }}>xxxxxxx</button> */}
                <MyDialog open={open} visivel={visivel} tipo={refTipo.current} retorno={()=>retorno(tipo)} fechar={()=>Fechar(tipo)} valor={valorCorreto.toString()} />
                <ItemConteudoDir2>
                  <TextConteudoDir1>Nome de exibição</TextConteudoDir1>
                  <ForaLinhaDir>
                    <input
                      value={nome}
                      className={'InputNovo'}
                      onInput={(e) => setNome(e.target.value)}
                      onBlur={() => {
                        onSubmitInflu();
                      }}
                    />
                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>


                <ItemConteudoDir2>
                  <TextConteudoDir1>Cidade</TextConteudoDir1>
                  <ForaLinhaDir>
                    <input
                      value={cidade}
                      className={'InputNovo'}
                      onInput={(e) => setCidade(e.target.value)}
                      onBlur={() => {
                        onSubmitCidade();
                      }}
                    />
                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>


                <ItemConteudoDir2>
                  <TextConteudoDir1>Estado</TextConteudoDir1>
                  <ForaLinhaDir>
                  <select onChange={(e) => MudaEstado("xxxx")} id="select" className="select" name="select">
                        <option id="0" value="0" {...estado === 0 && "selected"}>Selecione</option>
                        {estado === '1' ? <option id="1" value="1" selected>AC</option>:<option id="1" value="1">AC</option>}
                        {estado === '2' ? <option id="2" value="2" selected>AL</option>:<option id="2" value="2">AL</option>}
                        {estado === '3' ? <option id="3" value="3" selected>AP</option>:<option id="3" value="3">AP</option>}
                        {estado === '4' ? <option id="4" value="4" selected>AM</option>:<option id="4" value="4">AM</option>}
                        {estado === '5' ? <option id="5" value="5" selected>BA</option>:<option id="5" value="5">BA</option>}
                        {estado === '6' ? <option id="6" value="6" selected>CE</option>:<option id="6" value="6">CE</option>}
                        {estado === '7' ? <option id="7" value="7" selected>DF</option>:<option id="7" value="7">DF</option>}
                        {estado === '8' ? <option id="8" value="8" selected>ES</option>:<option id="8" value="8">ES</option>}
                        {estado === '9' ? <option id="9" value="9" selected>GO</option>:<option id="9" value="9">GO</option>}
                        {estado === '10' ? <option id="10" value="10" selected>MA</option>:<option id="10" value="10">MA</option>}
                        {estado === '11' ? <option id="11" value="11" selected>MT</option>:<option id="11" value="11">MT</option>}
                        {estado === '12' ? <option id="12" value="12" selected>MS</option>:<option id="12" value="12">MS</option>}
                        {estado === '13' ? <option id="13" value="13" selected>MG</option>:<option id="13" value="13">MG</option>}
                        {estado === '14' ? <option id="14" value="14" selected>PA</option>:<option id="14" value="14">PA</option>}
                        {estado === '15' ? <option id="15" value="15" selected>PB</option>:<option id="15" value="15">PB</option>}
                        {estado === '16' ? <option id="16" value="16" selected>PR</option>:<option id="16" value="16">PR</option>}
                        {estado === '17' ? <option id="17" value="17" selected>PE</option>:<option id="17" value="17">PE</option>}
                        {estado === '18' ? <option id="18" value="18" selected>PI</option>:<option id="18" value="18">PI</option>}
                        {estado === '19' ? <option id="19" value="19" selected>RJ</option>:<option id="19" value="19">RJ</option>}
                        {estado === '20' ? <option id="20" value="20" selected>RN</option>:<option id="20" value="20">RN</option>}
                        {estado === '21' ? <option id="21" value="21" selected>RS</option>:<option id="21" value="21">RS</option>}
                        {estado === '22' ? <option id="22" value="22" selected>RO</option>:<option id="22" value="22">RO</option>}
                        {estado === '23' ? <option id="23" value="23" selected>RR</option>:<option id="23" value="23">RR</option>}
                        {estado === '24' ? <option id="24" value="24" selected>SC</option>:<option id="24" value="24">SC</option>}
                        {estado === '25' ? <option id="25" value="25" selected>SP</option>:<option id="25" value="25">SP</option>}
                        {estado === '26' ? <option id="26" value="26" selected>SE</option>:<option id="26" value="26">SE</option>}
                        {estado === '27' ? <option id="27" value="27" selected>TO</option>:<option id="27" value="27">TO</option>}
                      </select>
                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>


                {localStorage.getItem("c_valor_tt") !== "null"  &&
                <ItemConteudoDir2>
                  <TextConteudoDir1>
                    Valor Médio por postagem Tiktok
                  </TextConteudoDir1>
                  <ForaLinhaDir>
                  {localStorage.getItem("c_data_tt") === "true" ? (
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""        
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={valor}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setValor(e.target.value)}
                      onBlur={() => {
                        onSubmitValorTikTok(
                          localStorage.getItem("c_seguidores_tt_extenso")
                        );
                    }}
                  />
                  ) : (
                    <CurrencyInput
                      disabled
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""        
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={valor}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setValor(e.target.value)}
                      onBlur={() => {
                        onSubmitValorTikTok(
                          localStorage.getItem("c_seguidores_tt_extenso")
                        );
                      }}
                    />
                  )}

                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>
              }





                {localStorage.getItem("c_seguidores_ig") !== "null" &&
                <ItemConteudoDir2>
                  <TextConteudoDir1>
                    Valor Médio por postagem instagram Reels
                  </TextConteudoDir1>
                  <ForaLinhaDir>
                  {localStorage.getItem("c_data_ig_reels") === "true" ? (
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={reels}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setReels(e.target.value)}
                      onBlur={() => {
                        onSubmitValorReels(
                          localStorage.getItem("c_views_medios_ig")
                        );
                    }}
                  />
                  ) : (
                    <CurrencyInput
                      disabled
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={reels}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setReels(e.target.value)}
                      onBlur={() => {
                        onSubmitValorReels(
                          localStorage.getItem("c_views_medios_ig")
                        );
                      }}
                    />
                  )}

                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>
                 }



                {localStorage.getItem("c_seguidores_ig") !== "null" &&
                <ItemConteudoDir2>
                  <TextConteudoDir1>
                    Valor Médio por postagem instagram Feed
                  </TextConteudoDir1>
                  <ForaLinhaDir>
  
                  {localStorage.getItem("c_data_ig_feed") === "true" ? (
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={feed}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setFeed(e.target.value)}
                      onBlur={() => {
                        onSubmitValorFeed(
                          localStorage.getItem("c_seguidores_ig_extenso")
                        );
                    }}
                  />
                  ) : (
                    <CurrencyInput
                      disabled
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      className={'InputNovo'}
                      // ref={register}
                      value={feed}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setFeed(e.target.value)}
                      onBlur={() => {
                        onSubmitValorFeed(
                          localStorage.getItem("c_seguidores_ig_extenso")
                        );
                      }}
                    />
                  )}

                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>
                }

                
                {localStorage.getItem("c_seguidores_ig") !== "null" &&
                <ItemConteudoDir2>
                  <TextConteudoDir1>
                    Valor Médio por postagem instagram Stories
                  </TextConteudoDir1>
                  <ForaLinhaDir>
                  {localStorage.getItem("c_data_ig_stories") === "true" ? (
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      // ref={register}
                      className={'InputNovo'}
                      value={stories}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setStories(e.target.value)}
                      onBlur={() => {
                        onSubmitValorStories(
                          localStorage.getItem("c_views_medios_ig")
                        );
                    }}
                    />
                  ) : (
                    <CurrencyInput
                      disabled
                      decimalSeparator=","
                      thousandSeparator="."
                      type="text"
                      mask=""
                      as={InputMask}
                      control={control}
                      // ref={register}
                      className={'InputNovo'}
                      value={stories}
                      name="c_valor"
                      id="c_valor"
                      onChangeEvent={(e) => setStories(e.target.value)}
                      onBlur={() => {
                        onSubmitValorStories(
                          localStorage.getItem("c_views_medios_ig")
                        );
                      }}
                    />
                  )}

                    <ForaEditar>
                      <ForaImagemEditar>
                        <ImagemEditar src={ImgEditar} />
                      </ForaImagemEditar>

                      <TextEditar>Editar</TextEditar>
                    </ForaEditar>
                  </ForaLinhaDir>
                  <RiscoItem></RiscoItem>
                </ItemConteudoDir2>
              }


                <ForaCategorias>
                  <TextCategorias>Categorias</TextCategorias>
                  <ForaItemCategoria>
                    {categoria.map((categoria, index) => (
                      <ItemCategoria
                        onClick={() => {
                          const data = JSON.stringify({
                            id_tb_user: localStorage.getItem("id_tb_user"),
                            id_tb_categoria: categoria.id_tb_categoria,
                            id_tb_status: categoria.id_tb_status,
                            index: index,
                          });
                          CategoriaUpdate(data);
                        }}
                      >
                        <QuadradoCategoria>
                          {categoria.id_tb_status && "x"}
                        </QuadradoCategoria>
                        <TextItemCategoria>
                          {categoria.c_nome}
                        </TextItemCategoria>
                      </ItemCategoria>
                    ))}

                    <RiscoCategoria></RiscoCategoria>

                    <ForaSenha>
                      <TextTrocarSenha>Trocar Senha</TextTrocarSenha>
                      <BotaoTrocarSenha onClick={() => AbreTrocaSenha()}>
                        Alterar Senha
                      </BotaoTrocarSenha>
                    </ForaSenha>
                  </ForaItemCategoria>
                </ForaCategorias>
              </ForaConteudoDir1>

              <ForaConteudoDir2>
                <TextEstat>Estatísticas</TextEstat>
                <ForaItemRedes>
                  <ForaImg>
                    <ImagemRedes src={ImgTik} />
                  </ForaImg>
                  <ForaTextRedes>
                    <TextRedes1>TikTok</TextRedes1>
                    <TextRedes2>Seguidores</TextRedes2>
                    <TextRedes3>
                      {localStorage.getItem("c_seguidores_tt")}
                    </TextRedes3>
                  </ForaTextRedes>
                </ForaItemRedes>


                {localStorage.getItem("c_seguidores_ig") !== "null" &&
                <ForaItemRedes>
                  <ForaImg>
                    <ImagemRedes src={ImgInsta} />
                  </ForaImg>
                  <ForaTextRedes>
                    <TextRedes1>Instagram</TextRedes1>
                    <TextRedes2>Seguidores</TextRedes2>
                    <TextRedes3>
                      {localStorage.getItem("c_seguidores_ig")}
                    </TextRedes3>
                  </ForaTextRedes>
                </ForaItemRedes>
               }

              </ForaConteudoDir2>
            </DireitaInfluencer>
          </ForaConteudoInfluencer>
        </ForaInfluencer>
      </FundoInfluencer>

      <Footer />      
    </>
  );
}

export default Login;
