import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


function MyDialog(props) {
  
  const [open, setOpen] = useState('');
  const [valor, setValor] = useState(0);
  const [myButtons, setMyButtons] = useState(true);

  useEffect( () => {
    setOpen(props.open);
    setValor(props.valor.replaceAll(".", ","))

  }, [props.open])

  const falarWhats = (async) => {
    setMyButtons(true);
    setOpen(false);
    props.visivel(false);
    window.open('https://wa.me/5511934018170');
  };

  const naoAceitar = () => {
    props.fechar(true);
    setMyButtons(false)
  };

  const aceitar = () => {
    // console.log('tipo', props.tipo);
    // console.log('valor', props.valor);
    setOpen(true);
    props.visivel(false);   
    // const myResp = {
    //   tipo: props.tipo,
    //   valor: props.valor
    // }
    props.retorno(props.ptipo, props.pvalor);
  };

  const fechar = () => {
    setOpen(false);
    props.visivel(false);   
    setMyButtons(true);

  };


  return (
    <>
      <div>
      
      <Dialog
        open={open}
        //TransitionComponent={Transition}
        keepMounted
        onClose={fechar}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"ATENÇÃO"}</DialogTitle>
        {myButtons?
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Para que a gente consiga te colocar no maior número de jobs, acreditamos que o melhor valor possível seja R$ {parseInt(valor).toFixed(2).replaceAll(".",",")}. Fazemos nossos cálculos de acordo com seu histórico de engajamento, tamanho e influenciadores (as) similares a você.
          </DialogContentText>
        </DialogContent>
        :
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Se você não concordar com os valores, manda uma mensagem pra gente no WhatsApp e conversamos sobre, ok? É Só clicar no botão abaixo.
          </DialogContentText>
        </DialogContent>
        }
        {myButtons?
        <DialogActions>
          <Button onClick={naoAceitar}>Não aceitar</Button>
          <Button onClick={aceitar}>Aceitar</Button>
        </DialogActions>
        :
        <DialogActions>
          <Button onClick={falarWhats}>Falar no Whatsapp</Button>
          <Button onClick={fechar}>Fechar</Button>
      </DialogActions>
        }
      </Dialog>
    </div>
    </>
  );
}

export default MyDialog;
