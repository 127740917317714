import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { socket, URLsocket } from "./Config";
import { AlertForm } from "./Funcoes";
import axios from "axios";
import {
    ForaImgRodape,
    TextMenuRodape,
    ImgRedesTopo,
    FundoRodape,
    ForaRodapeBaixo,
    LogoRodape,
    ImgLogoRodape,
    MenuRodape,
    RedesRodape,

} from "../Estilos";

import ImgLogoUrl from "../Images/LogoRodape.png";
import ImgBaixo1 from "../Images/RedesBaixo1.png";
import ImgBaixo2 from "../Images/RedesBaixo2.png";
import ImgBaixo3 from "../Images/RedesBaixo3.png";
import ImgBaixo4 from "../Images/RedesBaixo4.png";


function Rodape(props) {
  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [dataLogado, setDataLogado] = useState(false);

  const history = useHistory();

  const [login, setLogin] = useState("");

  const [senha, setSenha] = useState("");

  useEffect(() => {
    if (localStorage.getItem("status") === "true") {
      setDataLogado(true);
    }
  }, []);

  // const Banner = useRef(null);
  // const ComoFunciona = useRef(null);
  const Form = useRef(null);
  const Influenciadores = useRef(null);
  const Contato = useRef(null);
  const Servicos = useRef(null);

  const executeScroll = () => {
    // props.Banner.current.scrollIntoView();
    document.location = "/#Banner";
  };

  const executeScroll2 = () => {
    document.location = "/#ComoFunciona";
    // props.ComoFunciona.current.scrollIntoView();
  };

  const executeScroll3 = () => {
    // Form.current.scrollIntoView();
    document.location = "/#Form";
  };

  const executeScroll4 = () => {
    document.location = "/#Influenciadores";
    // Influenciadores.current.scrollIntoView();
  };

  const executeScroll5 = () => {
    document.location = "/#Contato";
    // Contato.current.scrollIntoView();
  };

  const executeScroll6 = () => {
    // Servicos.current.scrollIntoView();
    document.location = "/#Servicos";
  };

return (
    <>
      <FundoRodape>
        <ForaRodapeBaixo>
          <LogoRodape>
            <ImgLogoRodape src={ImgLogoUrl} />
          </LogoRodape>
          <MenuRodape>
            <TextMenuRodape onClick={() => executeScroll()}>
              Home
            </TextMenuRodape>
            <TextMenuRodape onClick={() => executeScroll6()}>
              Serviços
            </TextMenuRodape>
            <TextMenuRodape onClick={() => executeScroll4()}>
              Influenciadores
            </TextMenuRodape>
            <TextMenuRodape>login</TextMenuRodape>
            <TextMenuRodape>Contato</TextMenuRodape>
          </MenuRodape>

          <RedesRodape>
            <ForaImgRodape
              target="_blank"
              href="https://www.facebook.com/agenciawbmd"
            >
              <ImgRedesTopo src={ImgBaixo1} />
            </ForaImgRodape>
            <ForaImgRodape
              target="_blank"
              href="https://www.instagram.com/agenciawbmd/"
            >
              <ImgRedesTopo src={ImgBaixo2} />
            </ForaImgRodape>
            <ForaImgRodape
              target="_blank"
              href="https://www.linkedin.com/company/wbmd"
            >
              <ImgRedesTopo src={ImgBaixo3} />
            </ForaImgRodape>
            <ForaImgRodape
              target="_blank"
              href="https://www.youtube.com/channel/UCj7yKi4BoZLtNH2f1j5l-FA"
            >
              <ImgRedesTopo src={ImgBaixo4} />
            </ForaImgRodape>
          </RedesRodape>
        </ForaRodapeBaixo>
      </FundoRodape>
    </>
);

}

export default Rodape;
