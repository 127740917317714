import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { URLsocket } from "./Componentes/Config";
import { AlertForm } from "./Componentes/Funcoes";
import Header from "./Componentes/Header";
import Footer from "./Componentes/Rodape";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CurrencyInput from "react-currency-masked-input";
import "./index.css";

import {
  ForaImgBanner,
  ForaImgBannerResp,
  ImgBanner,
  FundoRodapeCima,
  ForaRodapeCima,
  TextRodapeCima,
  RiscoRodapeCima,
  BotaoContato,
  ForaLogoBanner,
  ImgLogoBanner,
  TextMarcas,
  TextAgencia,
  ForaImgMarca,
  ImgItemMarca,
  ForaImgItemMarca,
  RiscoMarca,
  ForaArtistas,
  ImgArtistas,
  ForaImgArtistas,
  ImgRisco1,
  ImgRisco2,
  FundoOferecemos,
  TextOferecemos,
  ForaOferecemos,
  ImgItemOferecemos,
  ForaImgOferecemos,
  ForaRiscoOferecemos,
  ForaRiscoOferecemos2,
  ForaComoFunciona,
  TextComoFunciona,
  ForaItemComoFunciona,
  ItemComoFuniona,
  ForaImgComoFunciona,
  TextComoFunciona1,
  TextComoFunciona2,
  RiscoFuncionaBaixo,
  FundoInfluenciadores,
  ForaInfluenciadores,
  TextInfluenciadores,
  ForaItenInfluenciadores,
  ItemInfluenciadores,
  ForaImgInfluenciadores,
  ImgInfluenciadores,
  TextItemInfluenciadores,
  ForaBaixoItemInfluenciadores,
  QuadradoTextInflu,
  ForaImgInfluCadastrados,
  ForaImgInfluCadastradosCenter,
  Catalogo,
  ForaCatalogo,
  ForaCatalogo2,
  FundoForm,
  ForaForm,
  TextForm,
  FormBriefing,
  CampoEmailCorp,
  CampoEmailCorp2,
  BotaoEnviar,
  ForaBotaoEnvia,
  RiscoBotaoEnvia,
  TextResponderemos,
  RiscoForm,
  ForaRiscoForm,
  FundoPopCatalogo,
  ForaPopCatalogo,
  TextPopCatalogo1,
  TextPopCatalogo2,
  FormCatalogo,
  CampoSenhaCatalogo,
  ForaBotaoEnviaCatalogo,
  BotaoEnviarCatalogo,
  RiscoPopCatalogo,
  BaixoPopCatalogo,
  BotaoSolicita,
  ForaSetaPopUp,
  SetaPop,
  ForaWhatsPop,
  TextWhatsPop,
  ForaImgWhats,
  ImagemWhats,
  TextXPop,
  ForaImgComoFunciona2,
  DivImgComoFunciona,
  ForaArtistaHover,
  RiscoArtista,
  TextArtistaHover,
} from "./Estilos";

import ImgBannerUrl from "./Images/ImgBanner.jpg";
import ImgBannerUrlResp from "./Images/ImgBannerResp.jpg";
import LogoBanner from "./Images/LogoBanner.png";
import ImgOferecemos1 from "./Images/RiscoOferecemos1.png";
import ImgOferecemos2 from "./Images/RiscoOferecemos2.png";
import ImgItemOferecemos1 from "./Images/ImgOferecemos1.png";
import ImgItemOferecemos2 from "./Images/ImgOferecemos2.png";
import ImgItemOferecemos3 from "./Images/ImgOferecemos3.png";
import ImgItemOferecemos4 from "./Images/ImgOferecemos4.png";
import ImgComoFunciona1 from "./Images/ImgComoFunciona1.png";
import ImgComoFunciona2 from "./Images/ImgComoFunciona2.png";
import ImgComoFunciona3 from "./Images/ImgComoFunciona3.png";
import ImgComoFunciona4 from "./Images/ImgComoFunciona4.png";

import ImgComoFunciona1Resp from "./Images/ImgComoFunciona1Resp.png";
import ImgComoFunciona2Resp from "./Images/ImgComoFunciona2Resp.png";
import ImgComoFunciona3Resp from "./Images/ImgComoFunciona3Resp.png";
import ImgComoFunciona4Resp from "./Images/ImgComoFunciona4Resp.png";

import ImgInfluCadastrados from "./Images/ImgInfluCadastrados.png";
import ImgCatalogo from "./Images/ImgCatalogo.png";
import ImgCatalogoResp from "./Images/ImgCatalogoResp.jpg";
import ImgSetaPop from "./Images/SetaPopUp.png";
import ImgWhatsPop from "./Images/ImgPopWhats.png";

function Login() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 15,
    slidesToScroll: 15,
    responsive: [
      {
        breakpoint: 1730,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 10,
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [comoFunciona, setComoFunciona] = useState([]);

  const [dataArtista, setDataArtista] = useState([]);

  const [verba, setVerba] = useState([]);

  const [dataInfluenciador, setDataInfluenciador] = useState([]);

  const [dataMarcas, setDataMarcas] = useState([]);

  const history = useHistory();

  const [dataLogado, setDataLogado] = useState(false);

  function AbreNomeArtista(x) {
    document.getElementById(x).style.display = "block";
  }

  function FechaNomeArtista(x) {
    document.getElementById(x).style.display = "none";
  }

  const [valor, setValor] = useState();
  const [valores, setValores] = useState([]);
  const [seguidores, setSeguidores] = useState(
    localStorage.getItem("c_seguidores_tt_extenso")
  );

  useEffect(() => {
    fetch(`https://wbmd.com.br/app/valores.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setValores(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const verifyPrice = async (price) => {
    let p_valor = 0;
    valores.map((item, key) => {
      if (
        price >= parseInt(item.c_minimo) &&
        price <= parseInt(item.c_maximo)
      ) {
        p_valor = item.c_valor;
      }
      console.log(price, item.c_minimo, item.c_maximo);
    });
    if (p_valor === 0) {
      p_valor = seguidores - 10000;
      p_valor = (p_valor / 1000) * 450 + 4000;
    }
    console.log(p_valor);
    if (valor <= p_valor) {
      alert("sucesso", "sucesso!");
    } else {
      alert("erro", "erro!");
    }
  };

  <>
    <InputMask
      type={"text"}
      value={valor}
      onChange={(event) => setValor(event.target.value)}
    />
    <button
      title="dsfasdf"
      onClick={() => {
        verifyPrice(valor);
      }}
    >
      teste
    </button>
    {valor}
  </>;

  useEffect(() => {
    fetch(`https://wbmd.com.br/app/ComoFunciona.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        //console.log(responseJson);
        //console.log(responseJson[0]);
        //console.log(responseJson[0].c_texto1);
        setComoFunciona(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`https://wbmd.com.br/app/artistas.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setDataArtista(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`https://wbmd.com.br/app/influenciador.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setDataInfluenciador(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`https://wbmd.com.br/app/marcas.php`, {})
      .then((response) => response.json())
      .then((responseJson) => {
        setDataMarcas(responseJson);
        //console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    if (localStorage.getItem("status") === "true") {
      setDataLogado(true);
    }
  }, []);

  const Banner = useRef(null);
  const ComoFunciona = useRef(null);
  const Form = useRef(null);
  const Influenciadores = useRef(null);
  const Contato = useRef(null);
  const Servicos = useRef(null);

  const executeScroll = () => {
    document.location = "/#Banner";
    // Banner.current.scrollIntoView();
  };

  // const executeScroll2 = () => ComoFunciona.current.scrollIntoView();

  const executeScroll3 = () => {
    document.location = "/#Form";
    //Form.current.scrollIntoView();
  };

  const executeScroll4 = () => {
    document.location = "/#Influenciadores";
    // Influenciadores.current.scrollIntoView();
  };

  const executeScroll5 = () => Contato.current.scrollIntoView();

  const executeScroll6 = () => Servicos.current.scrollIntoView();

  function AbrePopCatalogo() {
    document.getElementById("FundoPopCatalogo").style.display = "flex";
  }

  function FechaPopCatalogo() {
    document.getElementById("FundoPopCatalogo").style.display = "none";
  }

  function ValorReal() {
    var x = document.getElementById("c_verba").value;
    //alert("xxxxx");
  }

  function AbreEsqueciSenha() {
    document.getElementById("FormLogin").style.display = "none";
    document.getElementById("FormSenha").style.display = "block";
  }

  const onSubmit = async (data, e) => {
    if (data.c_briefing == "" || data.c_briefing == undefined) {
      AlertForm("O campo Briefing é de preenchimento obrigatório!");
      e.preventDefault();
    }
    if (data.c_email == "" || data.c_email == undefined) {
      AlertForm("O campo E-MAIL é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (data.c_instagram == "" || data.c_instagram == undefined) {
      AlertForm("O campo Instagram é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (verba == "" || verba == undefined) {
      AlertForm("O campo Verba é de preenchimento obrigatório!");
      e.preventDefault();
    } else {
      //alert(verba);
      if (verba < 500.000) {
        AlertForm("O Valor Minimo é de R$5,000");
        e.preventDefault();
      }
    }

    if (data.c_marca == "" || data.c_marca == undefined) {
      AlertForm("O campo Marca / Artista é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (data.c_nome == "" || data.c_nome == undefined) {
      AlertForm("O campo Nome é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (
      data.c_nome !== "" &&
      data.c_nome !== undefined &&
      data.c_marca !== "" &&
      data.c_marca !== undefined &&
      data.c_instagram !== "" &&
      data.c_instagram !== undefined &&
      data.c_email !== "" &&
      data.c_email !== undefined &&
      verba !== "" &&
      verba !== undefined &&
      data.c_briefing !== "" &&
      data.c_briefing !== undefined
    ) {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          data.c_email
        )
      ) {
        const response3 = await fetch(URLsocket + "app/envia_briefing.php", {      
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_nome: data.c_nome,
            c_marca: data.c_marca,
            c_verba: verba,
            c_instagram: data.c_instagram,
            c_email: data.c_email,
            c_briefing: data.c_briefing,
          }),
        });
        
        const jsonResult3 = await response3.json();
        //alert(jsonResult3[0].status);
        //console.log(jsonResult3[0].status);
        if (jsonResult3[0].status == true) {
          AlertForm("Mensagem enviada com sucesso!");
        } else {
          AlertForm("Mensagem não enviada!\nTente novamente.");
        }
      } else {
        AlertForm("O campo E-MAIL está com o formato incorreto!");
        e.preventDefault();
      }
    }
  };

  const onSubmit2 = async (data, e) => {
    if (data.c_login == "" || data.c_login == undefined) {
      AlertForm("O campo Login é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (data.c_senha == "" || data.c_senha == undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (
      data.c_login !== "" &&
      data.c_login !== undefined &&
      data.c_senha !== "" &&
      data.c_senha !== undefined
    ) {
      const response = await fetch(URLsocket + "app/login.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          c_login: data.c_login.toLowerCase(),
          c_senha: data.c_senha,
        }),
      });

      const jsonResult = await response.json();

      if (jsonResult[0].status === true) {
        AlertForm("Login efetuado com sucesso!");
        e.preventDefault();

        document.getElementById("FundoPopLogin").style.display = "none";
        setDataLogado(true);

        localStorage.setItem("status", jsonResult[0].status);
        localStorage.setItem("c_valor", jsonResult[0].c_valor);
        localStorage.setItem("c_nome", jsonResult[0].c_nome);
        localStorage.setItem("id_tb_user", jsonResult[0].id_tb_user);
        localStorage.setItem("c_login", jsonResult[0].c_login);
        localStorage.setItem("c_senha", jsonResult[0].c_senha);
        localStorage.setItem("c_seguidores_tt", jsonResult[0].c_seguidores_tt);
        localStorage.setItem(
          "c_seguidores_tt_extenso",
          jsonResult[0].c_seguidores_tt_extenso
        );
        localStorage.setItem(
          "c_views_medios_tt",
          jsonResult[0].c_views_medios_tt
        );
        localStorage.setItem("c_seguidores_ig", jsonResult[0].c_seguidores_ig);
        localStorage.setItem(
          "c_views_medios_ig",
          jsonResult[0].c_views_medios_ig
        );
      } else {
        AlertForm("Login ou senha incorretos!");
        e.preventDefault();
      }
    }
  };

  const onSubmit3 = async (data, e) => {
    if (data.c_senha == "" || data.c_senha == undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (
      data.c_senha !== "" &&
      data.c_senha !== undefined &&
      data.c_senha === "CatalogoWBMD##"
    ) {
      history.push("/Catalogo");
    } else {
      AlertForm("Senha Incorreta!");
      e.preventDefault();
    }
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1690 },
      items: 15,
    },
    tablet: {
      breakpoint: { max: 1690, min: 1130 },
      items: 10,
    },
    mobile: {
      breakpoint: { max: 1130, min: 640 },
      items: 6,
    },
    mobile2: {
      breakpoint: { max: 640, min: 0 },
      items: 3,
    },
  };

  return (
    <>
      <Header tipo={"home"} ComoFunciona={ComoFunciona} Banner={Banner} />

      <ForaImgBanner ref={Banner}>
        <ImgBanner src={ImgBannerUrl} />
      </ForaImgBanner>

      <ForaImgBannerResp ref={Banner}>
        <ImgBanner src={ImgBannerUrlResp} />
      </ForaImgBannerResp>

      <ForaLogoBanner>
        <ImgLogoBanner src={LogoBanner} />
      </ForaLogoBanner>

      <TextAgencia>
        Desenvolvemos a estratégia de marketing de influência, fazemos
        mapeamento de influencers, elaboramos contratos e produzimos relatórios
        com resultados das campanhas. Damos suporte para marcas e
        influenciadores durante todo processo.
      </TextAgencia>
      <TextAgencia>
        A WBMD sabe a agilidade que o mercado precisa no momento do orçamento
        para contratação de influenciadores e também da necessidade da
        pontualidade nas entregas. Por isso, possui uma equipe capacitada e
        acessível, do briefing à publicação.
      </TextAgencia>

      <TextMarcas>
        <b>Marcas que já conectamos</b>
      </TextMarcas>

      <ForaImgMarca>
        {dataMarcas.map((marcas) => (
          <ForaImgItemMarca>
            <ImgItemMarca
              src={
                "https://wbmd.com.br/upload/marca/" +
                marcas.id_tb_marca +
                "a." +
                marcas.c_extensao1
              }
            />
          </ForaImgItemMarca>
        ))}
      </ForaImgMarca>

      <RiscoMarca></RiscoMarca>

      <TextMarcas>
        <b>Artistas que já trabalhamos</b>
      </TextMarcas>

      <Slider {...settings} className="CarrouselArtistas">
        {dataArtista.map((artista) => (
          <ForaImgArtistas
            onMouseOut={() => FechaNomeArtista(artista.id_tb_artista)}
            onMouseOver={() => AbreNomeArtista(artista.id_tb_artista)}
          >
            <ImgArtistas
              src={
                "https://wbmd.com.br/upload/artista/" +
                artista.id_tb_artista +
                "a." +
                artista.c_extensao1
              }
            />
            <ForaArtistaHover id={artista.id_tb_artista}>
              <RiscoArtista></RiscoArtista>
              <TextArtistaHover>{artista.c_nome_artista}</TextArtistaHover>
            </ForaArtistaHover>
          </ForaImgArtistas>
        ))}
      </Slider>

      <ForaArtistas></ForaArtistas>

      <ForaRiscoOferecemos>
        <ImgRisco1 src={ImgOferecemos1} />
      </ForaRiscoOferecemos>

      <FundoOferecemos ref={Servicos} id="Servicos">
        <TextOferecemos>
          <b>O que oferecemos</b>
        </TextOferecemos>
        <ForaOferecemos>
          <ForaImgOferecemos>
            <ImgItemOferecemos src={ImgItemOferecemos1} />
          </ForaImgOferecemos>
          <ForaImgOferecemos>
            <ImgItemOferecemos src={ImgItemOferecemos2} />
          </ForaImgOferecemos>
          <ForaImgOferecemos>
            <ImgItemOferecemos src={ImgItemOferecemos3} />
          </ForaImgOferecemos>
          <ForaImgOferecemos>
            <ImgItemOferecemos src={ImgItemOferecemos4} />
          </ForaImgOferecemos>
        </ForaOferecemos>
      </FundoOferecemos>

      <ForaRiscoOferecemos2>
        <ImgRisco2 src={ImgOferecemos2} />
      </ForaRiscoOferecemos2>

      <ForaComoFunciona ref={ComoFunciona} id="ComoFunciona">
        <TextComoFunciona>
          <b>Como funciona</b>
        </TextComoFunciona>
        <ForaItemComoFunciona>
          <ItemComoFuniona>
            <DivImgComoFunciona>
              <ForaImgComoFunciona src={ImgComoFunciona1} />
            </DivImgComoFunciona>
            <DivImgComoFunciona>
              <ForaImgComoFunciona2 src={ImgComoFunciona1Resp} />
            </DivImgComoFunciona>

            <TextComoFunciona1>Planejamento</TextComoFunciona1>
            <TextComoFunciona2>
              Enviamos a mecânica para influenciadores. Nessa fase você recebe
              como seria a sinergia com cada influenciador e as mídias que cada
              um vai executar.
            </TextComoFunciona2>
          </ItemComoFuniona>
          <ItemComoFuniona>
            <DivImgComoFunciona>
              <ForaImgComoFunciona src={ImgComoFunciona2} />
            </DivImgComoFunciona>

            <DivImgComoFunciona>
              <ForaImgComoFunciona2 src={ImgComoFunciona2Resp} />
            </DivImgComoFunciona>
            <TextComoFunciona1>Execução</TextComoFunciona1>
            <TextComoFunciona2>
              Com a sua aprovação do planejamento executamos a campanha,
              mandamos nessa fase o board com o andamento de cada influenciador
              e seu resultado.
            </TextComoFunciona2>
          </ItemComoFuniona>
          <ItemComoFuniona>
            <DivImgComoFunciona>
              <ForaImgComoFunciona src={ImgComoFunciona3} />
            </DivImgComoFunciona>

            <DivImgComoFunciona>
              1
              <ForaImgComoFunciona2 src={ImgComoFunciona3Resp} />
            </DivImgComoFunciona>
            <TextComoFunciona1>Relatório</TextComoFunciona1>
            <TextComoFunciona2>
              Compilamos tudo em uma bela apresentação. Separamos as mídias
              utilizadas, links e números absolutos.
            </TextComoFunciona2>
          </ItemComoFuniona>
          <ItemComoFuniona>
            <DivImgComoFunciona>
              <ForaImgComoFunciona src={ImgComoFunciona4} />
            </DivImgComoFunciona>

            <DivImgComoFunciona>
              <ForaImgComoFunciona2 src={ImgComoFunciona4Resp} />
            </DivImgComoFunciona>
            <TextComoFunciona1>Pagamento</TextComoFunciona1>
            <TextComoFunciona2>
              Pagamento único e centralizado com a gente, alinhamos com os
              influenciadores prazos e notas ficais sem dor de cabeça.
            </TextComoFunciona2>
          </ItemComoFuniona>
        </ForaItemComoFunciona>

        <RiscoFuncionaBaixo></RiscoFuncionaBaixo>
      </ForaComoFunciona>

      <FundoInfluenciadores ref={Influenciadores} id="Influenciadores">
        <ForaInfluenciadores>
          <TextInfluenciadores>
            <b>ALGUNS INFLUENCIADORES PARCEIROS</b>
          </TextInfluenciadores>
          <ForaItenInfluenciadores>
            {dataInfluenciador.map((influenciador) => (
              <ItemInfluenciadores>
                <ForaImgInfluenciadores>
                  <ImgInfluenciadores
                    src={
                      "https://wbmd.com.br/upload/influenciador/" +
                      influenciador.id_tb_influenciador +
                      "a." +
                      influenciador.c_extensao1
                    }
                  />
                </ForaImgInfluenciadores>
                <ForaBaixoItemInfluenciadores>
                  <QuadradoTextInflu></QuadradoTextInflu>
                  <TextItemInfluenciadores>
                    {influenciador.c_nome_influenciador}
                  </TextItemInfluenciadores>
                </ForaBaixoItemInfluenciadores>
              </ItemInfluenciadores>
            ))}
          </ForaItenInfluenciadores>
        </ForaInfluenciadores>

        <ForaImgInfluCadastradosCenter>
          <ForaImgInfluCadastrados src={ImgInfluCadastrados} />
        </ForaImgInfluCadastradosCenter>
      </FundoInfluenciadores>

      <ForaCatalogo onClick={() => AbrePopCatalogo()}>
        <Catalogo src={ImgCatalogo} />
      </ForaCatalogo>

      <ForaCatalogo2 onClick={() => AbrePopCatalogo()}>
        <Catalogo src={ImgCatalogoResp} />
      </ForaCatalogo2>

      <FundoPopCatalogo id="FundoPopCatalogo">
        <ForaPopCatalogo>
          <TextXPop onClick={() => FechaPopCatalogo()}>X</TextXPop>
          <TextPopCatalogo1>catálogo completo</TextPopCatalogo1>

          <TextPopCatalogo2>
            Digite a sua senha de acesso para prosseguir:
          </TextPopCatalogo2>

          <FormCatalogo onSubmit={handleSubmit(onSubmit3)}>
            <CampoSenhaCatalogo>
              <Controller
                control={control}
                ref={register}
                id="c_senha"
                name="c_senha"
                type="text"
                placeholder={"Senha"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoSenhaCatalogo>
            <ForaBotaoEnviaCatalogo>
              <BotaoEnviarCatalogo type="submit">
                Acessar Catálogo
              </BotaoEnviarCatalogo>
            </ForaBotaoEnviaCatalogo>
          </FormCatalogo>

          <RiscoPopCatalogo></RiscoPopCatalogo>

          <TextPopCatalogo2>
            Ainda <span>não possui senha</span> de acesso?
          </TextPopCatalogo2>

          <BaixoPopCatalogo>
            <ForaSetaPopUp>
              <SetaPop src={ImgSetaPop} />
            </ForaSetaPopUp>
            <BotaoSolicita target="_blank" href="https://wa.me/5511934018170">
              Solicite acesso imediato
            </BotaoSolicita>

            <ForaWhatsPop>
              <TextWhatsPop>Via</TextWhatsPop>
              <ForaImgWhats>
                <ImagemWhats src={ImgWhatsPop} />
              </ForaImgWhats>
              <TextWhatsPop>WhatsApp</TextWhatsPop>
            </ForaWhatsPop>
          </BaixoPopCatalogo>
        </ForaPopCatalogo>
      </FundoPopCatalogo>

      <FundoForm ref={Form} id="Form">
        <ForaForm>
          <TextForm>
            <b>Envie seu Briefing</b>
          </TextForm>
          <FormBriefing onSubmit={handleSubmit(onSubmit)}>
            <CampoEmailCorp>
              <Controller
                control={control}
                ref={register}
                id="c_nome"
                name="c_nome"
                type="text"
                placeholder={"Nome"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoEmailCorp>
            <CampoEmailCorp>
              <Controller
                control={control}
                ref={register}
                id="c_marca"
                name="c_marca"
                type="text"
                placeholder={"Marca / Artista"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoEmailCorp>
            {/*         
            <CurrencyInput2
              //decimalSeparator="," 
              groupSeparator="." 
              name="c_verba"
              id="c_verba"
              placeholder="Verba"
              prefix="R$"
              //defaultValue={1000}
              decimalsLimit={2}
              className="CampoForm CampoFormLogin"
              onValueChange={(value) =>setVerba(value)}  
            />; */}
            <CampoEmailCorp>
              <CurrencyInput name="myInput" placeholder="Verba" onChange={(e) => setVerba(e.target.value)}  />
            </CampoEmailCorp>
           
            <CampoEmailCorp>
              <Controller
                control={control}
                ref={register}
                id="c_instagram"
                name="c_instagram"
                type="text"
                placeholder={"@ do Instagram"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoEmailCorp>
            <CampoEmailCorp>
              <Controller
                control={control}
                ref={register}
                id="c_email"
                name="c_email"
                type="text"
                placeholder={"E-mail"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoEmailCorp>
            <CampoEmailCorp2>
              <Controller
                control={control}
                ref={register}
                id="c_briefing"
                name="c_briefing"
                type="text"
                placeholder={"Briefing"}
                className="CampoForm CampoFormLogin"
                as={InputMask}
                mask=""
              />
            </CampoEmailCorp2>
            <ForaBotaoEnvia>
              <BotaoEnviar type="submit">Enviar Briefing</BotaoEnviar>
              <RiscoBotaoEnvia></RiscoBotaoEnvia>
              <TextResponderemos>
                Responderemos imediatamente!
              </TextResponderemos>
            </ForaBotaoEnvia>
            <ForaRiscoForm>
              <RiscoForm></RiscoForm>
            </ForaRiscoForm>
          </FormBriefing>
        </ForaForm>
      </FundoForm>

      <FundoRodapeCima ref={Contato} id="Contato">
        <ForaRodapeCima>
          <TextRodapeCima>Alguma dúvida? Entre em contato.</TextRodapeCima>
          <RiscoRodapeCima></RiscoRodapeCima>
          <BotaoContato target="_blank" href="https://wa.me/5511934018170">
            Entre em Contato
          </BotaoContato>
        </ForaRodapeCima>
      </FundoRodapeCima>

        <Footer />
    </>
  );
}

export default Login;
