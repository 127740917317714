import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, /*HashRouter, Router,*/ Route, Switch } from 'react-router-dom'
import './index.css'
import Login from './Login'
import Influencer from './Influencer'
import Catalogo from './Catalogo'

ReactDOM.render(
    <BrowserRouter basename="/">
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/Influencer" exact component={Influencer} />
            <Route path="/Catalogo" exact component={Catalogo} />
        </Switch>
    </BrowserRouter>,
    document.getElementById('root'),
)
