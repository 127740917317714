import styled from 'styled-components'
import BgInfluenciador from './Images/BgInfluenciadores.jpg'


export const ForaTopo = styled.div`
    display: flex;
    width: 1600px;
    margin: 0 auto;
    justify-content: space-between; 
    padding-top: 35px;
    padding-bottom: 35px;
    align-items: center;

    @media (max-width: 1635px) {
        width: 1428px;
    }

    @media (max-width: 1445px) {
        width: 1220px;
    }

    @media (max-width: 1245px) {
       width: 95%;
    }
`

export const LogoTopo = styled.img`

`    
export const ForaMenuTopo = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1445px) {
        width: 59%;
    }

    @media (max-width: 1245px) {
        display: none;
    }
`

export const TextMenuTopo = styled.a`

    :hover {
        border: 3px solid #000;
        cursor: pointer;
    }
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
    padding: 8px;
    border: 3px solid transparent;
`

export const ForaRedesTopo = styled.div`
    width: 280px;
    display: flex;
    justify-content: space-between; 

    @media (max-width: 1635px) {
        width: 200px;
    }

    @media (max-width: 1445px) {
        width: 170px;
    }

    @media (max-width: 530px) {
        display: none;
    }
    
`

export const ForaImgRedesTopo = styled.a`
    width: 35px;
`

export const ImgRedes = styled.img`
    width: 100%;
    height: auto;
`

export const BotaoLoginTopo = styled.a`
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: #000;

    @media (max-width: 1445px) {
        padding: 10px 10px;
    }
`

export const FundoRodape = styled.div`
   background-color: #000;
`

export const ForaRodapeBaixo = styled.div`
   width: 650px;
   display: flex;
   margin: 0 auto;
   padding-top: 105px;
   padding-bottom: 105px;
`

export const LogoRodape = styled.div`
   
`

export const ImgLogoRodape = styled.img`
   
`

export const MenuRodape = styled.div`
     margin-left: 30px;  
     margin-right: 54px;
`

export const TextMenuRodape = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 15px;
    text-transform: uppercase;
`

export const RedesRodape = styled.div`
   width: 320px;
   display: flex;
   border: 4px solid #fff;
   justify-content: center;
   align-items: center;
`

export const ImgRedesTopo = styled.img`
    width: 53px;
    height: 68px;
    margin-left: 5px;
    margin-right: 5px;
`




export const FundoPopLogin = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
`

export const ForaLogoPopLogin = styled.div`
    padding-top: 75px;
`

export const LogoPopLogin = styled.img`

`

export const TextPopLogin = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 35px;
    color: #000;
    padding-top: 30px;
    padding-bottom: 25px;

    @media (max-width: 540px) {
        font-size: 29px;
   }

   @media (max-width: 440px) {
        width: 90%;
        text-align: center;
   }
`

export const FormLogin = styled.form`

`

export const CampoFormLogin = styled.div`
    display: flex;
    justify-content: center;

    input {
        width: 425px;
        height: 70px;
        border: 2px solid #000;
        margin-top: 15px;
    }

    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    @media (max-width: 540px) {
    input {
        width: 330px;
        height: 55px;
        margin-top: 10px;
    }
    }

    @media (max-width: 440px) {
    input {
        width: 290px;
        height: 55px;
    }
    }
`

export const TextEsqueceuSenha = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #000;
    font-weight: 100;
    padding-top: 35px;
    padding-bottom: 35px;
`


export const ForaPopCatalogo = styled.div`
    width: 600px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 640px) {
        width: 90%;
    }
`

export const TextXPop = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 34px;
    color: #000;
    width: 554px;
    text-align: right;
    position: fixed;
    padding-top: 15px;
    cursor: pointer;

    @media (max-width: 640px) {
        width: 85%;
    }
`

export const ForaBotaoEnviaCatalogo = styled.div`
    width: 340px;
    margin: 0 auto;

    @media (max-width: 420px) {
        width: 260px;
    }
`

export const BotaoEnviarCatalogo = styled.button`
    background-color: #000;
    color: #fff;
    border: 0px;
    font-family: 'Lato',sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    width: 100%;
    height: 70px;
    margin-top: 21px;

    @media (max-width: 500px) {
        height: 60px;
        margin-top: 15px;
        font-size: 23px;
    }
`




export const FundoInfluencer = styled.div`
    background-image: url(${BgInfluenciador});
    background-color: #e2e2e2;
    background-repeat: no-repeat;
`

export const ForaInfluencer = styled.div`
    width: 1430px;
    margin: 0 auto;

    @media (max-width: 1465px) {
        width: 1140px;
    }

    @media (max-width: 1170px) {
        width: 880px;
    }

    @media (max-width: 900px) {
        width: 600px;
    }

    @media (max-width: 630px) {
        width: 410px;
    }

    @media (max-width: 440px) {
        width: 90%;
    }
`

export const TextInfluencer = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 50px;
    color: #000;
    text-transform: uppercase;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;

    
    @media (max-width: 630px) {
        font-size: 38px;
        padding-top: 50px;
        padding-bottom: 40px;
    }

    @media (max-width: 440px) {
        font-size: 30px;
    }

    @media (max-width: 440px) {
        font-size: 28px;
    }
`

export const ForaConteudoInfluencer = styled.div`
    display: flex;

    @media (max-width: 900px) {
        flex-direction: column;
        align-items: center;
    }

`

export const EsquerdaInfluencer = styled.div`
    padding-top: 100px;
    z-index: 99;

    @media (max-width: 1465px) {
        padding-top: 75px;
    }

    @media (max-width: 1170px) {
        padding-top: 0px;
        width: 309px;
        padding-bottom: 25px;
    }

    @media (max-width: 440px) {
        padding-bottom: 0px;
    }
`

export const ForaImgInfluencer = styled.div`

    @media (max-width: 440px) {
        width: 200px;
        margin: 0 auto;
    }

`

export const ImagemInfluencer = styled.img`

`

export const DireitaInfluencer = styled.div`
    background-color: #fff;
    display: flex;
    padding-top: 100px;
    padding-left: 250px;
    margin-left: -184px;
    padding-bottom: 190px;
    margin-bottom: 150px;

    @media (max-width: 1465px) {
        padding-left: 214px;
        margin-left: -184px;
        padding-top: 75px;
        padding-bottom: 130px;
    }

    @media (max-width: 1170px) {
        padding-left: 239px;
        margin-left: -184px;
        padding-top: 55px;
        padding-bottom: 70px;
        flex-direction: column;
        padding-right: 66px;
    }

    @media (max-width: 900px) {
        padding-left: 0px;
        margin-left: 0px;
        padding-right: 0px;
        width: 100%;
    }

    @media (max-width: 630px) {
        padding-top: 35px;
        padding-bottom: 35px;
        margin-top: 30px;
        margin-bottom: 70px;
    }
`

export const ForaConteudoDir1 = styled.div`
    width: 600px;

    @media (max-width: 1465px) {
        width: 450px;
    }

    @media (max-width: 900px) {
        width: 450px;
        margin: 0 auto;
    }

    @media (max-width: 630px) {
        width: 370px;
    }

    @media (max-width: 440px) {
        width: 90%;
    }

`

export const ItemConteudoDir = styled.div`
    width: 100%;
`

export const ItemConteudoDir2 = styled.div`
    width: 100%;
    margin-top: 30px;
`

export const TextConteudoDir1 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 18px;
    color: #b9b9b9;
    text-transform: uppercase;
`

export const ForaLinhaDir = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
`

export const TextStatus = styled.div`
    font-family: Lato, sans-serif;
    font-size: 24px;
    color: #000;
    font-weight: bold;
`

export const TextConteudoDir2 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 30px;
    color: #000;
`

export const FormInfluencer = styled.form`
    display: flex;
    justify-content: space-between;
    width: 100%;

    input {
        border: 0px;
        font-family: Lato, sans-serif;
        font-size: 30px;
        width: 80%;
    }
`










export const ForaImagemEditar = styled.div`
`

export const ForaEditar = styled.button`
    display: flex;
    width: 75px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background-color: transparent;
    border: 0px;
    padding: 0px;
    cursor: pointer;
`

export const ImagemEditar = styled.img`

`

export const TextEditar = styled.div`
   font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #b9b9b9;
`

export const RiscoItem = styled.div`
    width: 100%;
    background-color: #000;
    height: 2px;

`





export const ForaConteudoDir2 = styled.div`
    width: 200px;
    padding-left: 50px;
    padding-right: 76px;

    @media (max-width: 14645px) {
        padding-right: 20px;
    }

    @media (max-width: 1170px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;
    }

    @media (max-width: 900px) {
        width: 450px;
        padding-right: 0px;
        margin: 0 auto;
    }

    @media (max-width: 630px) {
        width: 370px;
    }

    @media (max-width: 440px) {
        width: 90%;
    }
`

export const ForaImg = styled.div`

`

export const ImagemRedes = styled.img`

`

export const ForaItemRedes = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
`

export const TextEstat = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #b9b9b9;
    text-transform: uppercase;

    @media (max-width: 1170px) {
        width: 100%;
        padding-top: 65px;
    }

    @media (max-width: 630px) {
        padding-top: 30px;
    }
`

export const ForaTextRedes = styled.div`
    @media (max-width: 1170px) {
        padding-left: 35px;
    }
`

export const TextRedes1 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
`

export const TextRedes2 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #b9b9b9;
    text-transform: uppercase;
`

export const TextRedes3 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 30px;
    color: #000;
    text-transform: uppercase;
`


export const ForaCategorias = styled.div`

`

export const TextCategorias = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 18px;
    color: #b9b9b9;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 15px;
`

export const ForaItemCategoria = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const RiscoCategoria = styled.div`
    width: 100%;
    background-color: #000;
    height: 2px;
    margin-top: 50px;
    margin-bottom: 50px;

    @media (max-width: 630px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

export const ItemCategoria = styled.div`
    display: flex;
    align-items: center;
    width: 48%;
    padding-top: 15px;

    @media (max-width: 440px) {
        width: 100%;
        padding-top: 8px;
    }
`

export const QuadradoCategoria = styled.div`
    width: 18px;
    height: 22px;
    border: 2px solid rgb(0, 0, 0);
    color: #000;
    text-align: center;
    font-size: 16px;
`

export const TextItemCategoria = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 20px;
    color: #000;
    padding-left: 15px;
    font-weight: 600;
`

export const TextTrocarSenha = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 18px;
    color: #b9b9b9;
    margin-bottom: 45px;
    text-transform: uppercase;

    @media (max-width: 630px) {
        margin-bottom: 25px;
    }
    
`

export const BotaoTrocarSenha = styled.a`
    font-family: 'Lato',sans-serif;
    font-size: 30px;
    color: #000;
    padding: 20px 60px;
    border: 1px solid #000;
    cursor: pointer;

    @media (max-width: 630px) {
        padding: 10px 50px;
    }

    @media (max-width: 440px) {
        font-size: 24px;
    }
`   

export const ForaSenha = styled.div`

    @media (max-width: 630px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
    }

`


export const FormTrocarSenha = styled.form`


`

export const ForaPopTrocarSenha = styled.div`
    width: 600px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;

    @media (max-width: 640px) {
        width: 90%;
        padding-bottom: 40px;
    }
`