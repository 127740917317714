import React, { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { socket, URLsocket } from "./Config";
import { AlertForm } from "./Funcoes";
import axios from "axios";
import {
  ForaTopo,
  LogoTopo,
  ForaMenuTopo,
  TextMenuTopo,
  TextSair,
  ForaRedesTopo,
  ForaRedesTopo2,
  ForaImgRedesTopo,
  ImgRedes,
  BotaoLoginTopo,
  BotaoLoginTopo2,
  ForaBotaoMobile,
  RiscoBotao,
  ForaPopCatalogo,
  ForaPopMenu,
  ForaBotaoEnviaCatalogo,
  BotaoEnviarCatalogo,
  TextXPop,
  TextXMenu,
  FundoPopLogin,
  FundoPopMenu,
  ForaLogoPopLogin,
  ForaLogoPopMenu,
  ForaItensMenu,
  TextItemMenu,
  LogoPopLogin,
  TextPopLogin,
  FormLogin,
  FormSenha,
  CampoFormLogin,
  TextEsqueceuSenha,
  ForaImgLogoTopo,
} from "../Estilos";

import ImgTopo from "../Images/LogoTopo.png";
import ImgRedes1 from "../Images/RedesTopo1.png";
import ImgRedes2 from "../Images/RedesTopo2.png";
import ImgRedes3 from "../Images/RedesTopo3.png";
import ImgRedes4 from "../Images/RedesTopo4.png";
import ImgLogoUrl from "../Images/LogoRodape.png";

import ImgLogoPopLogin from "../Images/LogoPopLogin.png";

function Header(props) {
  const { register, handleSubmit, errors, control, setValue } = useForm();

  const [dataLogado, setDataLogado] = useState(false);

  const history = useHistory();

  const [login, setLogin] = useState("");

  const [senha, setSenha] = useState("");

  useEffect(() => {
    if (localStorage.getItem("status") === "true") {
      setDataLogado(true);
    }
  }, []);

  // const Banner = useRef(null);
  // const ComoFunciona = useRef(null);
  const Form = useRef(null);
  const Influenciadores = useRef(null);
  const Contato = useRef(null);
  const Servicos = useRef(null);

  const executeScroll = () => {
    // props.Banner.current.scrollIntoView();
    document.location = "/#Banner";
  };

  const executeScroll2 = () => {
    document.location = "/#ComoFunciona";
    // props.ComoFunciona.current.scrollIntoView();
  };

  const executeScroll3 = () => {
    // Form.current.scrollIntoView();
    document.location = "/#Form";
  };

  const executeScroll4 = () => {
    document.location = "/#Influenciadores";
    // Influenciadores.current.scrollIntoView();
  };

  const executeScroll5 = () => {
    document.location = "/#Contato";
    // Contato.current.scrollIntoView();
  };

  const executeScroll6 = () => {
    // Servicos.current.scrollIntoView();
    document.location = "/#Servicos";
  };

  function LogOut() {
    setTimeout(() => {
      localStorage.removeItem("status");
      localStorage.removeItem("c_nome");
      localStorage.removeItem("id_tb_user");
      localStorage.removeItem("c_login");
      localStorage.removeItem("c_senha");
      localStorage.removeItem("c_seguidores_tt");
      localStorage.removeItem("c_views_medios_tt");
      localStorage.removeItem("c_seguidores_ig");
      localStorage.removeItem("c_views_medios_ig");
      localStorage.removeItem("c_senha");

      localStorage.removeItem("c_data_tt");
      localStorage.removeItem("c_data_ig_feed");
      localStorage.removeItem("c_data_ig_stories");
      localStorage.removeItem("c_data_ig_reels");



      setDataLogado(false);
      //history.replace('/');
      history.push("/");
    }, 500);
  }

  function RedirecionaInflu() {
    // history.push("/Influencer#teste");
    document.location = "/Influencer";
  }

  function AbreMenu() {
    document.getElementById("PopMenu").style.display = "flex";
  }

  function FechaMenu() {
    document.getElementById("PopMenu").style.display = "none";
  }

  function TiraMenu() {
    document.getElementById("PopMenu").style.display = "none";
  }

  function AbrePopLogin() {
    document.getElementById("FundoPopLogin").style.display = "flex";
  }

  function AbrePopLogin2() {
    document.getElementById("FundoPopLogin").style.display = "flex";
  }

  function FechaPopLogin() {
    document.getElementById("FundoPopLogin").style.display = "none";
    //document.getElementById("FormLogin").style.display = "block";
    document.getElementById("FormSenha").style.display = "none";
    document.getElementById("BotaoLogar").style.display = "block";
    document.getElementById("c_login").style.display = "block";
    document.getElementById("c_senha").style.display = "block";
  }

  function AbreEsqueciSenha() {
    document.getElementById("FormSenha").style.display = "block";
    document.getElementById("BotaoLogar").style.display = "none";
    document.getElementById("c_login").style.display = "none";
    document.getElementById("c_senha").style.display = "none";
  }

  const onLogin = async () => {
    let valida = true;

    if (login === "" || login === undefined) {
      AlertForm("O campo Login é de preenchimento obrigatório!");
      valida = false;
      //e.preventDefault();
    }

    if (senha === "" || senha === undefined) {
      AlertForm("O campo Senha é de preenchimento obrigatório!");
      valida = false;
      //e.preventDefault();
    }

    if (valida) {

      axios
        .post(
          URLsocket + "app/login.php",
          {
            c_login: login.toLowerCase(),
            c_senha: senha,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(function (response) {

          const jsonResult = response.data;

          console.log(jsonResult[0]);

          if (jsonResult[0].status === true) {
            console.log("sucesso");
            setDataLogado(true);

            localStorage.setItem("status", jsonResult[0].status);
            localStorage.setItem("c_valor_tt", jsonResult[0].c_valor_tt);
            localStorage.setItem(
              "c_valor_ig_feed",
              jsonResult[0].c_valor_ig_feed
            );
            localStorage.setItem(
              "c_valor_ig_stories",
              jsonResult[0].c_valor_ig_stories
            );
            localStorage.setItem(
              "c_valor_ig_reels",
              jsonResult[0].c_valor_ig_reels
            );
            localStorage.setItem("c_nome", jsonResult[0].c_nome);
            localStorage.setItem("id_tb_user", jsonResult[0].id_tb_user);
            localStorage.setItem("c_login", jsonResult[0].c_login);
            localStorage.setItem("c_senha", jsonResult[0].c_senha);
            localStorage.setItem(
              "c_seguidores_tt_extenso",
              jsonResult[0].c_seguidores_tt_extenso
            );
            localStorage.setItem(
              "c_seguidores_tt",
              jsonResult[0].c_seguidores_tt
            );
            localStorage.setItem(
              "c_seguidores_ig_extenso",
              jsonResult[0].c_seguidores_ig_extenso
            );
            localStorage.setItem(
              "c_views_medios_tt",
              jsonResult[0].c_views_medios_tt
            );
            localStorage.setItem(
              "c_seguidores_ig",
              jsonResult[0].c_seguidores_ig
            );
            localStorage.setItem(
              "c_views_medios_ig",
              jsonResult[0].c_views_medios_ig
            );

            //alert(jsonResult[0].c_data_tt);

            localStorage.setItem("c_data_tt", jsonResult[0].c_data_tt);
            localStorage.setItem("c_data_ig_feed", jsonResult[0].c_data_ig_feed);
            localStorage.setItem("c_data_ig_stories", jsonResult[0].c_data_ig_stories);
            localStorage.setItem("c_data_ig_reels", jsonResult[0].c_data_ig_reels);
            localStorage.setItem("id_tb_estado", jsonResult[0].id_tb_estado);
            localStorage.setItem("c_cidade", jsonResult[0].c_cidade);
            localStorage.setItem("c_extensao1", jsonResult[0].c_extensao1);



            history.push("/Influencer");
          } else {
            AlertForm("Login ou senha incorretos!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      // const response = await fetch(URLsocket + "app/login.php", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     c_login: login.toLowerCase(),
      //     c_senha: senha,
      //   }),
      // });

      //const jsonResult = await response.json();
    }
  };

  const onSenha = async (data, e) => {
    if (data.c_email === "" || data.c_email === undefined) {
      AlertForm("O campo E-mail é de preenchimento obrigatório!");
      e.preventDefault();
    }

    if (
      data.c_email !== "" &&
      data.c_email !== undefined &&
      data.c_email !== "" &&
      data.c_email !== undefined
    ) {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          data.c_email
        )
      ) {
        const response = await fetch(URLsocket + "app/EsqSenha.php", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            c_email: data.c_email.toLowerCase(),
          }),
        });

        const jsonResult = await response.json();

        if (jsonResult[0].status === true) {
          AlertForm("Senha enviada no e-mail!");
          e.preventDefault();
        } else {
          AlertForm("Erro!");
          e.preventDefault();
        }
      } else {
        AlertForm("O campo E-MAIL está com o formato incorreto!");
        e.preventDefault();
      }
    }
  };

  if (props.tipo === "home") {
    return (
      <>
        <ForaTopo id="ForaTopo">
          <ForaImgLogoTopo href="https://www.wbmd.com.br/">
            <LogoTopo src={ImgTopo} />
          </ForaImgLogoTopo>
          <ForaMenuTopo>
            <TextMenuTopo onClick={() => executeScroll()}>HOME</TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll6()}>
              Serviços
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll2()}>
              como funciona
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll3()}>
              enviar briefing
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll4()}>
              Influenciadores
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll5()}>
              Contato
            </TextMenuTopo>
          </ForaMenuTopo>

          <ForaRedesTopo>
            <ForaImgRedesTopo
              target="_blank"
              href="https://www.facebook.com/agenciawbmd"
            >
              <ImgRedes src={ImgRedes1} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.instagram.com/agenciawbmd/"
            >
              <ImgRedes src={ImgRedes2} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.linkedin.com/company/wbmd"
            >
              <ImgRedes src={ImgRedes3} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.youtube.com/channel/UCj7yKi4BoZLtNH2f1j5l-FA"
            >
              <ImgRedes src={ImgRedes4} />
            </ForaImgRedesTopo>
          </ForaRedesTopo>

          {dataLogado === false ? (
            <BotaoLoginTopo onClick={() => AbrePopLogin()}>
              Login influenciadores
            </BotaoLoginTopo>
          ) : (
            <>
              <BotaoLoginTopo onClick={() => RedirecionaInflu()}>
                Meu Perfil
              </BotaoLoginTopo>
              <TextSair onClick={() => LogOut()}>Sair</TextSair>
            </>
          )}

          <ForaBotaoMobile onClick={() => AbreMenu()}>
            <RiscoBotao></RiscoBotao>
            <RiscoBotao></RiscoBotao>
            <RiscoBotao></RiscoBotao>
          </ForaBotaoMobile>
        </ForaTopo>

        <FundoPopMenu id="PopMenu">
          <ForaPopMenu>
            <TextXMenu onClick={() => FechaMenu()}>X</TextXMenu>
            <ForaLogoPopMenu>
              <LogoPopLogin src={ImgLogoUrl} />
            </ForaLogoPopMenu>
            <ForaItensMenu>
              <TextItemMenu onClick={() => executeScroll() + TiraMenu()}>
                HOME
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll6()}>
                Serviços
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll2() + TiraMenu()}>
                como funciona
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll3() + TiraMenu()}>
                enviar briefing
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll4() + TiraMenu()}>
                Influenciadores
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll5() + TiraMenu()}>
                Contato
              </TextItemMenu>
            </ForaItensMenu>

            <ForaRedesTopo2>
              <ForaImgRedesTopo
                target="_blank"
                href="https://www.facebook.com/agenciawbmd"
              >
                <ImgRedes src={ImgRedes1} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.instagram.com/agenciawbmd/"
              >
                <ImgRedes src={ImgRedes2} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.linkedin.com/company/wbmd"
              >
                <ImgRedes src={ImgRedes3} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.youtube.com/channel/UCj7yKi4BoZLtNH2f1j5l-FA"
              >
                <ImgRedes src={ImgRedes4} />
              </ForaImgRedesTopo>
            </ForaRedesTopo2>

            <BotaoLoginTopo2 onClick={() => AbrePopLogin2()}>
              Login influenciadores
            </BotaoLoginTopo2>
          </ForaPopMenu>
        </FundoPopMenu>

        <FundoPopLogin id="FundoPopLogin">
          <ForaPopCatalogo>
            <TextXPop onClick={() => FechaPopLogin()}>X</TextXPop>
            <ForaLogoPopLogin>
              <LogoPopLogin src={ImgLogoPopLogin} />
            </ForaLogoPopLogin>

            <TextPopLogin>LOGIN de INFLUENCIADOR</TextPopLogin>

            <CampoFormLogin>
              <input
                id="c_login"
                name="c_login"
                value={login}
                placeholder={"Usuário"}
                onChange={(e) => setLogin(e.target.value)}
              />
            </CampoFormLogin>
            <CampoFormLogin>
              <input
                id="c_senha"
                name="c_senha"
                value={senha}
                placeholder={"Senha"}
                onChange={(e) => setSenha(e.target.value)}
                type="password"
              />
            </CampoFormLogin>
            <ForaBotaoEnviaCatalogo>
              <BotaoEnviarCatalogo id="BotaoLogar" onClick={() => onLogin()} type="button">
                Logar
              </BotaoEnviarCatalogo>
            </ForaBotaoEnviaCatalogo>

            <FormSenha id="FormSenha" onSubmit={handleSubmit(onSenha)}>
              <CampoFormLogin>
                <Controller
                  control={control}
                  ref={register}
                  id="c_email"
                  name="c_email"
                  type="password"
                  placeholder={"E-mail Cadastrado"}
                  className="CampoForm CampoFormLogin"
                  as={InputMask}
                  mask=""
                />
              </CampoFormLogin>
              <ForaBotaoEnviaCatalogo>
                <BotaoEnviarCatalogo type="submit">Logar</BotaoEnviarCatalogo>
              </ForaBotaoEnviaCatalogo>
            </FormSenha>


            <TextEsqueceuSenha onClick={() => AbreEsqueciSenha()}>
              Esqueceu sua senha?
            </TextEsqueceuSenha>
          </ForaPopCatalogo>
        </FundoPopLogin>
      </>
    );
  } else {
    return (
      <>
        <ForaTopo id="ForaTopo">
          <ForaImgLogoTopo href="https://www.wbmd.com.br/">
            <LogoTopo src={ImgTopo} />
          </ForaImgLogoTopo>
          <ForaMenuTopo>
            <TextMenuTopo onClick={() => executeScroll()}>HOME</TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll6()}>
              Serviços
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll2()}>
              como funciona
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll3()}>
              enviar briefing
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll4()}>
              Influenciadores
            </TextMenuTopo>
            <TextMenuTopo onClick={() => executeScroll5()}>
              Contato
            </TextMenuTopo>
          </ForaMenuTopo>

          <ForaRedesTopo>
            <ForaImgRedesTopo
              target="_blank"
              href="https://www.facebook.com/agenciawbmd"
            >
              <ImgRedes src={ImgRedes1} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.instagram.com/agenciawbmd/"
            >
              <ImgRedes src={ImgRedes2} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.linkedin.com/company/wbmd"
            >
              <ImgRedes src={ImgRedes3} />
            </ForaImgRedesTopo>

            <ForaImgRedesTopo
              target="_blank"
              href="https://www.youtube.com/channel/UCj7yKi4BoZLtNH2f1j5l-FA"
            >
              <ImgRedes src={ImgRedes4} />
            </ForaImgRedesTopo>
          </ForaRedesTopo>

          {dataLogado === false ? (
            <BotaoLoginTopo onClick={() => AbrePopLogin()}>
              Login influenciadores
            </BotaoLoginTopo>
          ) : (
            <>
              <BotaoLoginTopo onClick={() => RedirecionaInflu()}>
                Meu Perfil
              </BotaoLoginTopo>
              <TextSair onClick={() => LogOut()}>Sair</TextSair>
            </>
          )}

          <ForaBotaoMobile onClick={() => AbreMenu()}>
            <RiscoBotao></RiscoBotao>
            <RiscoBotao></RiscoBotao>
            <RiscoBotao></RiscoBotao>
          </ForaBotaoMobile>
        </ForaTopo>

        <FundoPopMenu id="PopMenu">
          <ForaPopMenu>
            <TextXMenu onClick={() => FechaMenu()}>X</TextXMenu>
            <ForaLogoPopMenu>
              <LogoPopLogin src={ImgLogoUrl} />
            </ForaLogoPopMenu>
            <ForaItensMenu>
              <TextItemMenu onClick={() => executeScroll() + TiraMenu()}>
                HOME
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll6() + TiraMenu()}>
                Serviços
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll2() + TiraMenu()}>
                como funciona
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll3() + TiraMenu()}>
                enviar briefing
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll4() + TiraMenu()}>
                Influenciadores
              </TextItemMenu>
              <TextItemMenu onClick={() => executeScroll5() + TiraMenu()}>
                Contato
              </TextItemMenu>
            </ForaItensMenu>

            <ForaRedesTopo2>
              <ForaImgRedesTopo
                target="_blank"
                href="https://www.facebook.com/agenciawbmd"
              >
                <ImgRedes src={ImgRedes1} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.instagram.com/agenciawbmd/"
              >
                <ImgRedes src={ImgRedes2} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.linkedin.com/company/wbmd"
              >
                <ImgRedes src={ImgRedes3} />
              </ForaImgRedesTopo>

              <ForaImgRedesTopo
                target="_blank"
                href="https://www.youtube.com/channel/UCj7yKi4BoZLtNH2f1j5l-FA"
              >
                <ImgRedes src={ImgRedes4} />
              </ForaImgRedesTopo>
            </ForaRedesTopo2>

            <BotaoLoginTopo2 onClick={() => AbrePopLogin2()}>
              Login influenciadores
            </BotaoLoginTopo2>
          </ForaPopMenu>
        </FundoPopMenu>

        <FundoPopLogin id="FundoPopLogin">
          <ForaPopCatalogo>
            <TextXPop onClick={() => FechaPopLogin()}>X</TextXPop>
            <ForaLogoPopLogin>
              <LogoPopLogin src={ImgLogoPopLogin} />
            </ForaLogoPopLogin>

            <TextPopLogin>LOGIN de INFLUENCIADOR</TextPopLogin>

            <CampoFormLogin>
              <input
                id="c_login"
                name="c_login"
                value={login}
                placeholder={"Usuário"}
                onChange={(e) => setLogin(e.target.value)}
              />
            </CampoFormLogin>
            <CampoFormLogin>
              <input
                id="c_senha"
                name="c_senha"
                value={senha}
                placeholder={"Senha"}
                onChange={(e) => setSenha(e.target.value)}
              />
            </CampoFormLogin>
            <ForaBotaoEnviaCatalogo>
            <BotaoEnviarCatalogo id="BotaoLogar" onClick={() => onLogin()} type="button">
                Logar
              </BotaoEnviarCatalogo>
            </ForaBotaoEnviaCatalogo>

            <FormSenha id="FormSenha" onSubmit={handleSubmit(onSenha)}>
              <CampoFormLogin>
                <Controller
                  control={control}
                  ref={register}
                  id="c_email"
                  name="c_email"
                  type="password"
                  placeholder={"E-mail Cadastrado"}
                  className="CampoForm CampoFormLogin"
                  as={InputMask}
                  mask=""
                />
              </CampoFormLogin>
              <ForaBotaoEnviaCatalogo>
                <BotaoEnviarCatalogo type="submit">Logar</BotaoEnviarCatalogo>
              </ForaBotaoEnviaCatalogo>
            </FormSenha>

            <TextEsqueceuSenha onClick={() => AbreEsqueciSenha()}>
              Esqueceu sua senha?
            </TextEsqueceuSenha>
          </ForaPopCatalogo>
        </FundoPopLogin>
      </>
    );
  }
}

export default Header;
