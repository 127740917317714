import styled from 'styled-components'
import BgOferecemos from './Images/BgOferecemos.jpg'
import BgInfluenciadores from './Images/BgInfluenciadores.jpg'
import BgForm from './Images/BgForm.jpg'

export const ForaTopo = styled.div`
    display: flex;
    width: 1600px;
    margin: 0 auto;
    justify-content: space-between; 
    padding-top: 35px;
    padding-bottom: 35px;
    align-items: center;

    @media (max-width: 1635px) {
        width: 1428px;
    }

    @media (max-width: 1445px) {
        width: 1220px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media (max-width: 1245px) {
       width: 95%;
    }

    @media (max-width: 500px) {
       width: 90%;
       padding-top: 20px;
       padding-bottom: 20px;
    }
`

export const LogoTopo = styled.img`

`    

export const ForaImgLogoTopo = styled.a`

`

export const ForaMenuTopo = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1445px) {
        width: 59%;
    }

    @media (max-width: 1245px) {
        display: none;
    }
`

export const TextMenuTopo = styled.a`

    :hover {
        border: 3px solid #000;
        cursor: pointer;
    }
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #000;
    text-transform: uppercase;
    padding: 8px;
    border: 3px solid transparent;
`

export const ForaRedesTopo = styled.div`
    width: 280px;
    display: flex;
    justify-content: space-between; 

    @media (max-width: 1635px) {
        width: 200px;
    }

    @media (max-width: 1445px) {
        width: 150px;
    }

    @media (max-width: 560px) {
        display: none;
    }
    
`

export const ForaImgRedesTopo = styled.a`
    width: 35px;
`

export const ImgRedes = styled.img`
    width: 100%;
    height: auto;
`

export const BotaoLoginTopo = styled.a`
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: #000;
    cursor: pointer;

    @media (max-width: 1445px) {
        padding: 10px 5px;
        font-size: 13px;
    }
    
    @media (max-width: 400px) {
        display: none;
    }
`

export const TextSair = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #000;
    cursor: pointer;
`



export const ForaBotaoMobile = styled.div`
    display: none;
    cursor: pointer;

    @media (max-width: 1245px) {
        display: block;
    }
`

export const RiscoBotao = styled.div`
    background-color: #000;
    width: 51px;
    height: 7px;
    margin-top: 7px;
    border-radius: 4px;
`



export const ForaImgBanner = styled.div`
     @media (max-width: 800px) {
        display: none;
    }
`

export const ForaImgBannerResp = styled.div`
   display: none;

   @media (max-width: 800px) {
        display: block;
    }
`



export const ImgBanner = styled.img`
   width: 100%;
   height: auto;
`

export const ForaLogoBanner = styled.div`
    margin: 0 auto;
    width: 325px;
    margin-top: -78px;

    @media (max-width: 1050px) {
        width: 230px;
    }

    @media (max-width: 500px) {
        width: 190px;
        margin-top: -37px;
    }
`

export const ImgLogoBanner = styled.img`
   width: 100%;
   height: auto;
`

export const TextMarcas = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    color: #000;
    margin-top: 80px;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 620px) {
        font-size: 35px;
        margin-top: 40px;
    }

    @media (max-width: 445px) {
        font-size: 35px;
        margin-top: 32px;
    }
`

export const TextAgencia = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 26px;
    color: #000;
    text-align: center;
    width: 1044px;
    margin: 0 auto;
    line-height: 35px;
    margin-top: 40px;

    @media (max-width: 1320px) {
        width: 845px;
        font-size: 23px;
    }

    @media (max-width: 880px) {
        width: 580px;
        font-size: 20px;
        line-height: 26px;
        margin-top: 45px;
    }

    @media (max-width: 600px) {
        width: 90%;
    }
`

export const ForaImgMarca = styled.div`
    width: 1660px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    @media (max-width: 1665px) {
        width: 1485px;
    }

    @media (max-width: 1485px) {
        width: 1485px;
    }

    @media (max-width: 1485px) {
        width: 990px;
        display: flex;
        flex-wrap: wrap;
    }

    @media (max-width: 1035px) {
        width: 795px;
    }

    @media (max-width: 815px) {
        width: 537px;
    }

    @media (max-width: 550px) {
        width: 200px;
        flex-direction: column;
        margin-top: 25px;
    }
`

export const ImgItemMarca = styled.img`
`

export const ForaImgItemMarca = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 550px) {
        padding-top: 25px;
        justify-content: center;
    }
`


export const RiscoMarca = styled.div`
    width: 1040px;
    background-color: #e1e1e1;
    height: 3px;
    margin: 0 auto;
    margin-top: 75px;
    margin-bottom: 75px;

    
    @media (max-width: 1040px) {
        width: 95%;
    }

    @media (max-width: 550px) {
        margin: 40px auto;
    }

    @media (max-width: 450px) {
        margin-top: 50px;
        margin-bottom: 50px;
    }
`



export const ForaArtistaHover = styled.div`
    display: none;
    width: 130px;
    margin-left: -15px;
`

export const RiscoArtista = styled.div`
    width: 10px;
    height: 25px;
    background-color: #000;
    margin: 0 auto;
`

export const TextArtistaHover = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 20px;
    color: rgb(0, 0, 0);
    text-align: center;
`



export const ForaArtistas = styled.div`
    width: 1660px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    margin-top: 60px;

    @media (max-width: 1690px) {
        width: 1425px;
    }

    @media (max-width: 1445px) {
        width: 1180px;
    }

    @media (max-width: 1200px) {
        width: 695px;
        flex-wrap: wrap;
        margin-top: 35px;
    }

    @media (max-width: 720px) {
        width: 380px;
    }

    @media (max-width: 405px) {
        width: 305px;
    }
`
export const ImgArtistas = styled.img`
    filter: grayscale(1);
    cursor: pointer;

    :hover {
        filter: grayscale(0);
    }

    @media (max-width: 1130px) {
        width: 85px;
    }

`

export const ForaImgArtistas = styled.div`
    width: 140px;

    
    @media (max-width: 1690px) {
        width: 90px;
    }
`


export const ImgRisco1 = styled.img`
    margin-bottom: -106px;

    @media (max-width: 720px) {
        margin-top: 70px;
    }

    @media (max-width: 600px) {
        margin-top: 0px;
    }
`

export const ImgRisco2 = styled.img`
    margin-top: -9.6vw;
    width: 50%;
    
    @media (max-width: 1250px) {
        margin-top: -130px;
        width: 678px;
        padding-bottom: 0px;
    }

    @media (max-width: 730px) {
        margin-top: -83px;
        width: 430px;
        padding-bottom: 0px;
    }
`


export const ForaRiscoOferecemos = styled.div`
    display: flex;
    justify-content: center;  
`
export const ForaRiscoOferecemos2 = styled.div`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 530px) {
        display: none;
    }
`

export const FundoOferecemos = styled.div`
    background-image: url(${BgOferecemos});
    padding-top: 200px;
    padding-bottom: 15vw;

    @media (max-width: 1570px) {
        padding-top: 160px;
        padding-bottom: 18vw;
    }

    @media (max-width: 900px) {
        padding-bottom: 28vw;
    }

    @media (max-width: 530px) {
        padding-top: 140px;
        padding-bottom: 68px;
    }
`

export const TextOferecemos = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 530px) {
        font-size: 29px;
    }
`

export const ForaOferecemos = styled.div`
    width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 25px;

    @media (max-width: 1570px) {
        width: 1200px;
    }

    @media (max-width: 1250px) {
        width: 750px;
    }

    @media (max-width: 900px) {
        width: 487px;
    }

    @media (max-width: 530px) {
        width: 305px;
    }

`

export const ImgItemOferecemos = styled.img`
    padding-top: 60px;


    @media (max-width: 935px) {
        width: 485px;
        padding-top: 30px;
        margin: 0 auto;
    }
`

export const ForaImgOferecemos = styled.div`
    width: 48%;

    @media (max-width: 900px) {
        width: 100%;
    }
`


export const RiscoOferecemos2 = styled.img`

`




export const ForaComoFunciona = styled.div`
    width: 1160px;
    margin: 0 auto;

    
    @media (max-width: 1570px) {
        width: 1180px;
    }

    @media (max-width: 1205px) {
        width: 850px;
    }

    @media (max-width: 880px) {
        width: 500px;
    }

    @media (max-width: 590px) {
        width: 330px;
    }

    @media (max-width: 390px) {
        width: 280px;
    }
`

export const TextComoFunciona = styled.div`
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
    text-transform: uppercase;

    @media (max-width: 1205px) {
        padding-top: 28px;
        padding-bottom: 50px;
    }

    @media (max-width: 880px) {
        padding-top: 28px;
        padding-bottom: 10px;
    }

    @media (max-width: 550px) {
        font-family: 'Lato',sans-serif;
        font-size: 37px;
    }
`

export const ForaItemComoFunciona = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 880px) {
        flex-wrap: wrap;
    }

`

export const ItemComoFuniona = styled.div`
    display: flex;
    flex-direction: column;
    width: 24%;

    @media (max-width: 880px) {
        width: 47%;
        padding-top: 40px;
    }

    @media (max-width: 880px) {
        width: 100%;
        padding-top: 30px;
    }
`

export const TextComoFunciona1 = styled.div`
    color: #000;
    font-family: 'Lato',sans-serif;
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
    padding-left: 26px;
    padding-top: 25px;

    @media (max-width: 1570px) {
        font-size: 31px;
    }
    
    @media (max-width: 1205px) {
        font-size: 25px;
        padding-top: 20px;
        padding-left: 20px;
    }

    @media (max-width: 880px) {
        text-align: center;
    }
`

export const TextComoFunciona2 = styled.div`
    color: #000;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    padding-left: 26px;
    padding-top: 20px;

    @media (max-width: 1205px) {
        padding-left: 20px;
        padding-top: 15px;
    }

    @media (max-width: 880px) {
        text-align: center;
    }
`

export const DivImgComoFunciona = styled.div`
    width: 100%;

    @media (max-width: 470px) {
        width: 70%;
        margin: 0 auto;
    }
`

export const ForaImgComoFunciona = styled.img`

    width: 100%;
    @media (max-width: 880px) {
        display: none;
    }
`

export const ForaImgComoFunciona2 = styled.img`
    display: none;
    width: 100%;

    @media (max-width: 880px) {
        display: block;
    }
`



export const RiscoFuncionaBaixo = styled.div`
    width: 10px;
    background-color: #000;
    height: 90px;
    margin: 0 auto;
    margin-top: 74px;
`






export const FundoInfluenciadores = styled.div`
    background-image: url(${BgInfluenciadores});
    background-repeat: no-repeat;
    background-color: #e1e1e1;
`

export const ForaInfluenciadores = styled.div`
    width: 1640px;
    margin: 0 auto;

    @media (max-width: 1670px) {
        width: 1250px;
    }

    @media (max-width: 1310px) {
        width: 1000px;
    }

    @media (max-width: 1030px) {
        width: 790px;
    }

    @media (max-width: 820px) {
        width: 550px;
    }

    @media (max-width: 580px) {
        width: 390px;
    }

    @media (max-width: 400px) {
        width: 305px;
    }
`

export const TextInfluenciadores = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    text-align: center;
    color: #000;
    padding-top: 150px;
    text-transform: uppercase;

    @media (max-width: 1030px) {
        padding-top: 75px;
    }

    @media (max-width: 580px) {
        font-size: 39px;
        line-height: 50px;
    }

    @media (max-width: 400px) {
        padding-top: 50px;
        font-size: 31px;
        line-height: 37px;
    }
`

export const ForaItenInfluenciadores = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    flex-wrap: wrap;

    @media (max-width: 1310px) {
        padding-top: 10px;
    }

    @media (max-width: 1030px) {
        width: 790px;
    }

    @media (max-width: 820px) {
        width: 100%;
    }

    @media (max-width: 400px) {
        padding-top: 0px;
    }

`

export const ItemInfluenciadores = styled.div`
    width: 16.66%;
    padding-top: 20px;
    padding-bottom: 8px;

    :hover {
        background-color: #fff;
    }

    @media (max-width: 1030px) {
        width: 19%;
        padding-top: 40px;
    }

    @media (max-width: 820px) {
        width: 31%;
    }

    @media (max-width: 400px) {
        width: 48%;
    }
   
`

export const ForaImgInfluenciadores = styled.div`
    display: flex;
    align-items: center;
`

export const ImgInfluenciadores = styled.img`
    filter: grayscale(1);
    cursor: pointer;
    width: 90%;
    margin: 0 auto;

    :hover {
        filter: grayscale(0);
    }
`


export const TextItemInfluenciadores = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 25px;
    color: #000;
    padding-left: 10px;
`

export const ForaBaixoItemInfluenciadores = styled.div`
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-top: 5px;
`

export const QuadradoTextInflu = styled.div`
    width: 15px;
    height: 15px;
    background-color: #000;
`

export const ForaImgInfluCadastradosCenter = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 60px;
    width: 860px;
    margin: 0 auto;

    @media (max-width: 1150px) {
        width: 90%;
        margin: 0 auto;
    }

    @media (max-width: 500px) {
        width: 90%;
        padding-top: 55px;
    }
`

export const ForaImgInfluCadastrados = styled.img`
    padding-bottom: 200px;
`


export const Catalogo = styled.img`
    margin-top: -114px;
    width: 100%;
`

export const ForaCatalogo = styled.div`
    cursor: pointer;
    @media (max-width: 800px) {
        display: none;
    }
`

export const ForaCatalogo2 = styled.div`
        display: none;
        cursor: pointer;
     @media (max-width: 800px) {
        display: block;
    }
`



export const FundoForm = styled.div`
    background-image: url(${BgForm});
    background-repeat: no-repeat;
    background-position: bottom;
`

export const ForaForm = styled.div`
    width: 665px;
    margin: 0 auto;

    @media (max-width: 1250px) {
        width: 655px;
    }

    @media (max-width: 700px) {
        width: 450px;
    }

    @media (max-width: 490px) {
        width: 80%;
    }

`

export const TextForm = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    color: #000;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 25px;
    width: 100%;
    text-transform: uppercase;

    @media (max-width: 1250px) {
        width: 100%;
    }

    @media (max-width: 1100px) {
        padding-bottom: 25px;
    }

    @media (max-width: 700px) {
        padding-top: 40px;
        padding-bottom: 35px;
    }

    @media (max-width: 490px) {
        padding-top: 25px;
        padding-bottom: 20px;
        font-size: 37px;
        line-height: 38px;
    }
`

export const FormBriefing = styled.form`
    display: flex;
    flex-direction: column;
`

export const CampoEmailCorp = styled.div`
    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    input {
        width: 100%;
        height: 65px;
        border: 2px solid #000000;
        margin-top: 20px;
        width: 750px;
        padding-left: 3%;
        width: 97%;
        font-family: 'Lato',sans-serif;
        font-size: 32px;
    }

    @media (max-width: 1250px) {
        input {
            width: 97%;
        }
    }


    @media (max-width: 1100px) {
        input {     
            height: 75px;
            font-size: 20px;
            margin-top: 15px;
        }
    }

    @media (max-width: 490px) {
        input {
            height: 50px;
        }

        input::placeholder {
            padding-left: 10px;
        }

    }
`

export const CurrencyInput = styled.div`
    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    input {
        width: 100%;
        height: 65px;
        border: 2px solid #000000;
        margin-top: 20px;
        width: 750px;
        padding-left: 3%;
        width: 97%;
        font-family: 'Lato',sans-serif;
        font-size: 32px;
    }

    @media (max-width: 1250px) {
        input {
            width: 97%;
        }
    }


    @media (max-width: 1100px) {
        input {     
            height: 75px;
            font-size: 20px;
            margin-top: 15px;
        }
    }

    @media (max-width: 490px) {
        input {
            height: 50px;
        }

        input::placeholder {
            padding-left: 10px;
        }

    }
`


export const CampoEmailCorp2 = styled.div`
    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    input {
        width: 100%;
        height: 165px;
        border: 2px solid #000000;
        margin-top: 20px;
        padding-left: 3%;
        width: 97%;
        font-family: 'Lato',sans-serif;
        font-size: 32px;
    }


    @media (max-width: 1250px) {
        input {
            width: 97%;
        }
    }

    @media (max-width: 1100px) {
        input {
            width: 97%;
            font-size: 20px;
            height: 160px;
        }
    }

    @media (max-width: 490px) {
        input {
            height: 120px;
            margin-top: 15px;
        }

        input::placeholder {
            padding-left: 10px;
        }
    }
`

export const BotaoEnviar = styled.button`
        font-family: 'Lato', sans-serif;
        font-size: 32px;
        color: #fff;
        background-color: #000;
        border: 0px;
        width: 315px;
        height: 75px;
        cursor: pointer;

        @media (max-width: 1250px) {
            width: 380px;
            height: 80px;
        }

        @media (max-width: 700px) {
            width: 100%;
        }

        @media (max-width: 490px) {
            height: 65px;
        }
`

export const ForaBotaoEnvia = styled.div`
   display: flex;
   margin-top: 30px;
   align-items: center;
   justify-content: flex-end;
   margin-right: -210px;

   @media (max-width: 1250px) {
        margin-right: 0px;
        width: 100%;
    }

    @media (max-width: 1100px) {
        margin-top: 30px;
    }

    @media (max-width: 700px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @media (max-width: 490px) {
        margin-top: 30px;
    }
`

export const RiscoBotaoEnvia = styled.div`
    width: 120px;
    height: 10px;
    background-color: #000;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 700px) {
        width: 145px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 30px;
    }

    @media (max-width: 490px) {
        width: 78px;
    }
`

export const TextResponderemos = styled.div`
        font-family: 'Lato', sans-serif;
        font-size: 25px;
        color: #000;
        font-weight: 800;
        text-transform: uppercase;
        width: 220px;

        @media (max-width: 700px) {
            margin-top: 30px;
            font-size: 19px;
            width: 175px;
        }
`

export const RiscoForm = styled.div`
    width: 10px;
    height: 140px;
    background-color: #000;
    margin: 0 auto;
    margin-top: 25px;

    @media (max-width: 500px) {
        height: 100px;
    }
`

export const ForaRiscoForm = styled.div`

`





export const FundoRodapeCima = styled.div`
   background-color: #e1e1e1;
`

export const ForaRodapeCima = styled.div`
   display: flex;
   width: 1050px;
   margin: 0 auto;
   align-items: center;
   padding-top: 105px;
   padding-bottom: 105px;

   @media (max-width: 1170px) {
        width: 740px;
   }

   @media (max-width: 845px) {
        width: 740px;
   }

   @media (max-width: 830px) {
        width: 600px;
   }

   @media (max-width: 670px) {
        width: 600px;
   }

   @media (max-width: 670px) {
        padding-top: 60px;
        padding-bottom: 60px;
        width: 395px;
        flex-direction: column;
   }
   
   @media (max-width: 430px) {
        width: 90%;
   }

`

export const TextRodapeCima = styled.div`
    font-family: 'Lato', sans-serif;
    font-size: 50px;
    color: #000;
    width: 435px;

    @media (max-width: 1170px) {
        font-size: 35px;
   }

   @media (max-width: 670px) {
        width: 100%;
   }

   @media (max-width: 430px) {
        font-size: 31px;
         line-height: 32px;
   }
`


export const RiscoRodapeCima = styled.div`
    width: 250px;
    background-color: #000;
    height: 10px;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 670px) {
        display: none;
   }
`

export const ForaImgRodape = styled.a`

`

export const BotaoContato = styled.a`
    background-color: #000;
    font-family: 'Lato',sans-serif;
    font-size: 32px;
    width: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95px;
    cursor: pointer;
    color: #fff;

    @media (max-width: 845px) {
        font-size: 26px;
        width: 420px;
        height: 80px;
   }

   @media (max-width: 670px) {
        margin-top: 30px;
   }

   @media (max-width: 670px) {
        width: 100%;
   }
`






export const FundoRodape = styled.div`
   background-color: #000;
`

export const ForaRodapeBaixo = styled.div`
   width: 650px;
   display: flex;
   margin: 0 auto;
   padding-top: 105px;
   padding-bottom: 105px;

   @media (max-width: 690px) {
        width: 478px;
   }

   @media (max-width: 500px) {
        width: 376px;
        flex-direction: column;
        align-items: center;
        padding-top: 35px;
        padding-bottom: 40px;
   }

   @media (max-width: 380px) {
        width: 335px;
   }
`

export const LogoRodape = styled.div`
   
`

export const ImgLogoRodape = styled.img`
   
`

export const MenuRodape = styled.div`
     margin-left: 30px;  
     margin-right: 54px;

     @media (max-width: 690px) {
        margin-left: 15px;
        margin-right: 8px;
    }

    @media (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
        text-align: center; 
    }
`

export const TextMenuRodape = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 500px) {
        font-size: 17px;
        padding-top: 10px;
    }
`

export const RedesRodape = styled.div`
   width: 320px;
   display: flex;
   border: 4px solid #fff;
   justify-content: center;
   align-items: center;

   @media (max-width: 690px) {
        width: 212px;
    }

    @media (max-width: 500px) {
        width: 270px;
        justify-content: space-between;
        margin-top: 30px;
    }
`

export const ImgRedesTopo = styled.img`
    width: 53px;
    height: 68px;
    margin-left: 5px;
    margin-right: 5px;

    @media (max-width: 690px) {
        margin-left: 0px;
        margin-right: 0px;
    }
`



export const FundoPopMenu = styled.div`
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    @media (max-width: 450px) {
        align-items: unset;
        padding-top: 50px;
    }
`


export const ForaPopMenu = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 640px) {
        width: 90%;
    }
`

export const TextXMenu = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 34px;
    color: #fff;
    position: fixed;
    padding-top: 15px;
    cursor: pointer;
    top: 35px;
    right: 50px;

    @media (max-width: 640px) {
        top: 0px;
        right: 20px;
    }

    
`

export const ForaLogoPopMenu = styled.div`
    display: flex;
    justify-content: center;
`

export const ForaItensMenu = styled.div`

`

export const TextItemMenu = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 35px;
    color: #FFF;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 30px;

    @media (max-width: 450px) {
        text-align: center;
        font-size: 27px;
    }
`

export const BotaoLoginTopo2 = styled.a`
    font-family: 'Lato',sans-serif;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    padding: 15px 40px;
    background-color: #000;
    margin-top: 25px;
    cursor: pointer;

    @media (max-width: 1445px) {
        padding: 10px 10px;
    }

    
`

export const ForaRedesTopo2 = styled.div`
    width: 280px;
    display: flex;
    justify-content: space-between; 
    margin-top: 27px;

    @media (max-width: 1635px) {
        width: 200px;
    }

    @media (max-width: 1445px) {
        width: 170px;
    }
`





export const FundoPopLogin = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
`

export const ForaLogoPopLogin = styled.div`
    padding-top: 75px;

    @media (max-width: 440px) {
        width: 70px;
   }
`

export const LogoPopLogin = styled.img`

`

export const TextPopLogin = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 35px;
    color: #000;
    padding-top: 30px;
    padding-bottom: 25px;

    @media (max-width: 540px) {
        font-size: 29px;
   }

   @media (max-width: 440px) {
        width: 90%;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 15px;
   }
`

export const FormSenha = styled.form`
    display: none;
`

export const FormLogin = styled.form`

`

export const CampoFormLogin = styled.div`
    display: flex;
    justify-content: center;

    input {
        width: 410px;
        height: 70px;
        border: 2px solid #000;
        margin-top: 15px;
        font-family: 'Lato',sans-serif;
        font-size: 25px;
        color: #000;
        padding-left: 15px;
    }

    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    @media (max-width: 540px) {
    input {
        width: 330px;
        height: 55px;
        margin-top: 10px;
    }
    }

    @media (max-width: 440px) {
    input {
        width: 260px;
        height: 45px;
    }
    }
`

export const TextEsqueceuSenha = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #000;
    font-weight: 100;
    padding-top: 20px;
    padding-bottom: 20px;
    cursor: pointer;
`



export const FundoPopCatalogo = styled.div`
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
`

export const TextXPop = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 34px;
    color: #000;
    width: 554px;
    text-align: right;
    position: fixed;
    padding-top: 15px;
    cursor: pointer;

    @media (max-width: 640px) {
        width: 85%;
    }
`

export const ForaPopCatalogo = styled.div`
    width: 600px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 640px) {
        width: 90%;
    }
`

export const TextPopCatalogo1 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 34px;
    color: #000;
    text-transform: uppercase;
    margin-top: 70px;
    margin-bottom: 30px;

    @media (max-width: 640px) {
        margin-top: 60px;
        margin-bottom: 10px;
        font-size: 27px;
    }

    @media (max-width: 420px) {
        font-size: 23px;
    }
`

export const TextPopCatalogo2 = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #000;
    margin-bottom: 20px;

    span {
        font-weight: bold;
    }

    @media (max-width: 420px) {
        width: 90%;
        text-align: center;
    }
`

export const FormCatalogo = styled.form`

`

export const CampoSenhaCatalogo = styled.div`
    input {
        width: 410px;
        height: 70px;
        padding-left: 15px;
        border: 2px solid #000;
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        color: #000;
    }

    input::placeholder {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        color: #000;
        font-weight: 800;
        padding-left: 20px;
    }

    @media (max-width: 500px) {
        input {
            width: 100%;
            height: 50px;
            border: 2px solid #000;
        }
    }

    @media (max-width: 420px) {
        input {
            width: 252px;
            height: 50px;
        }
    }
`

export const ForaBotaoEnviaCatalogo = styled.div`
    width: 340px;
    margin: 0 auto;

    @media (max-width: 420px) {
        width: 260px;
    }
`

export const BotaoEnviarCatalogo = styled.button`
    background-color: #000;
    color: #fff;
    border: 0px;
    font-family: 'Lato',sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    width: 100%;
    height: 70px;
    margin-top: 21px;
    cursor: pointer;

    @media (max-width: 500px) {
        height: 45px;
        margin-top: 15px;
        font-size: 23px;
    }
`

export const RiscoPopCatalogo = styled.div`
    width: 425px;
    background-color: #000;
    height: 2px;
    margin-top: 45px;
    margin-bottom: 45px;

    @media (max-width: 500px) {
        margin-top: 25px;
        margin-bottom: 25px;
        width: 90%;
    }
`   

export const BaixoPopCatalogo = styled.div`
    background-color: #309a13;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
`

export const BotaoSolicita = styled.a`
    font-family: 'Lato',sans-serif;
    font-size: 25px;
    color: #fff;
    background-color: #000;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px 35px;
    margin: 0 auto;
    margin-top: 10px;

    @media (max-width: 500px) {
        padding: 15px 15px;
        font-size: 20px;
    }

    @media (max-width: 420px) {
        padding: 15px 10px;
        font-size: 18px;
    }
`

export const ForaSetaPopUp = styled.div`
    display: flex;
    justify-content: center;
`

export const SetaPop = styled.img`

`

export const ForaWhatsPop = styled.div`
    display: flex;
    width: 135px;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 10px;
`

export const TextWhatsPop = styled.div`
    font-family: 'Lato',sans-serif;
    font-size: 17px;
    color: #fff;
`

export const ForaImgWhats = styled.div`

`

export const ImagemWhats = styled.img`

`
